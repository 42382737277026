import * as home from './home'
import * as admin from './admin'

import { pageName } from '@/config'
import { CUSTOM_LOGO_REG } from 'api/config'
const env = process.env.VUE_APP_RUNTIME
let adminBase = env === 'development' ? '/admin.html/pcb/' : '/pcb/'
const pathname = window.location.pathname.replace(/#(.*?)$/, '').split('/').filter(item => item)
if (((env === 'production' || env === 'test') && CUSTOM_LOGO_REG.test(pathname[0]))) {
  console.log(pathname[0])
  adminBase = '/' + pathname[0] + adminBase
  console.log(adminBase)
}
const config = {
  [pageName.home]: {
    base: {
      mode: 'history'
    },
    ...home
  },
  [pageName.admin]: {
    base: {
      mode: 'history',
      base: adminBase
    },
    ...admin
  }
}
export default {
  ...config[window.$pageName]
}
