const requireAll = requireContext => requireContext.keys().map(requireContext)
const imgList = []
imgList.push(require.context('./aboutUs/certificate', false))
imgList.push(require.context('./aboutUs/img', false))
imgList.push(require.context('./crowdSourcingServices/images', false))
imgList.push(require.context('./home/banner', false))
imgList.push(require.context('./home/img', false))
imgList.push(require.context('./hroServices/images', false))
imgList.push(require.context('./img', false, /(\.png|\.jpeg)$/))
imgList.push(require.context('./img/logo', false))
imgList.push(require.context('./insuranceAgency/images'))
imgList.push(require.context('./partnerPlan/images', false))
imgList.push(require.context('./recruitService/images', false))
imgList.push(require.context('./settlementServices/images', false))

for (let i = 0; i < imgList.length; i++) {
  requireAll(imgList[i])
}
