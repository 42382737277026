export const PROJECT_NAME = {
  YXQN: 'yxqn'
}

/*
  用户角色
 */
export const rolesType = {
  DEFAULT: 0,
  AGENT: 1, // 人力
  EMPLOYER: 2 // 商户
}

export const pageName = {
  home: 'home',
  admin: 'admin'
}
