import permission from './permission'
import checkPermission from '@/utils/permission'
import { PERMISSIONS } from './permission.config'

const install = function(Vue) {
  Vue.directive('permission', permission)
  Vue.prototype.$checkPermission = checkPermission
  Vue.mixin({
    data() {
      return { PERMISSIONS }
    }
  })
}

if (window.Vue) {
  window['permission'] = permission
  Vue.use(install); // eslint-disable-line
}

permission.install = install
export default permission
