import store from 'store'
import { sysUtil } from '@/utils'

function createComment(el, vnode) {
  const comment = document.createComment('div')
  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined
  })
  vnode.elm = comment
  vnode.text = ' '
  vnode.isComment = true
  vnode.context = undefined
  vnode.tag = undefined
  vnode.data.directives = undefined
  if (vnode.componentInstance) {
    vnode.componentInstance.$el = comment
  }
  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el)
  }
}

function check(el, binding, vnode) {
  const { value } = binding
  const role = store.getters.roles
  const permission = store.getters.permission
  const roles = [...role, ...permission]
  const isAdmin = roles.includes('admin')
  if (value && typeof value === 'string') {
    if (roles.includes(value) || isAdmin) {
      return
    }
    el.parentNode && el.parentNode.removeChild(el)
    return
  }

  if (value && value instanceof Array && value.length > 0) {
    if (isAdmin) {
      return
    }
    const permissionRoles = value

    const hasPermission = roles.some(r => {
      return permissionRoles.includes(r)
    })

    if (!hasPermission) {
      el.parentNode && el.parentNode.removeChild(el)
    }
    return
  }
  if (value && value instanceof Object) {
    let { permission = [], condition = [] } = value
    if (sysUtil.object.isObject(permission)) {
      permission = Object.keys(permission).filter(key => {
        const conditions = permission[key]
        if (Array.isArray(conditions)) {
          return conditions.every(item => item === true)
        }
        return !!permission[key]
      })
    }

    const canShow = condition.every(item => item === true)
    const hasPermission = roles.some(role => {
      return permission.includes(role)
    })
    if (!canShow) {
      createComment(el, vnode)
      return
    }
    if (!hasPermission) {
      createComment(el, vnode)
      return
    }
    return
  }
  throw new Error(
    `need roles! Like v-permission="['add','del']"
      or
      v-permission="{
              permission: ['resetPaymentPassword'],
              condition: [scope.row.balance !== '']
            }"
      or
       v-permission="resetPaymentPassword"
      or
       v-permission="{
               permission: {
                admin: true,
                riskCheckPassBtn: orderSignedRate
              }
            }"
            `
  )
}

export default {
  inserted(el, binding, vnode) {
    check(el, binding, vnode)
  },
  update(el, binding, vnode) {
    check(el, binding, vnode)
  }
}
// import store from '@/store'
//
// /* @flow */
//
// function locateNode(vnode) {
//   return vnode.componentInstance && (!vnode.data || !vnode.data.transition)
//     ? locateNode(vnode.componentInstance._vnode)
//     : vnode
// }
//
// function check(el, binding, vnode) {
//   console.log(vnode)
//   vnode = locateNode(vnode)
//   const originalDisplay = (el.__vOriginalDisplay =
//     el.style.display === 'none' ? '' : el.style.display)
//   const vm = vnode.context
//   // const componentInstance = vnode.componentInstance
//   const { value } = binding
//   const roles = store.getters && store.getters.roles
//   const isAdmin = roles.includes('admin')
//   if (value && typeof value === 'string') {
//     if (roles.includes(value) || isAdmin) {
//       el.style.display = originalDisplay
//       return
//     }
//     el.style.display = 'none'
//     // el.parentNode && el.parentNode.removeChild(el)
//     return
//   }
//
//   if (value && value instanceof Array && value.length > 0) {
//     if (isAdmin) {
//       return
//     }
//     const permissionRoles = value
//
//     const hasPermission = roles.some(role => {
//       return permissionRoles.includes(role)
//     })
//
//     if (!hasPermission) {
//       el.parentNode && el.parentNode.removeChild(el)
//     }
//     return
//   }
//   if (value && value instanceof Object) {
//     vm.$nextTick(() => {
//       const { permission = [], condition = [] } = value
//       const canShow = condition.every(item => item === true)
//       if (!canShow) {
//         // el.style.visibility = 'hidden'
//         el.style.display = 'none'
//         // el.parentNode && el.parentNode.removeChild(el)
//         return
//       }
//       el.style.display = el.__vOriginalDisplay
//       // el.parentNode && el.parentNode.appendChild(componentInstance.$el)
//       // componentInstance && componentInstance.$forceUpdate()
//       if (isAdmin) {
//         return
//       }
//       const hasPermission = roles.some(role => {
//         return permission.includes(role)
//       })
//       if (!hasPermission) {
//         el.style.display = 'none'
//         // el.parentNode && el.parentNode.removeChild(el)
//       }
//     })
//     return
//   }
//   throw new Error(
//     `need roles! Like v-permission="['add','del']"
//       or
//       v-permission="{
//               permission: ['resetPaymentPassword'],
//               condition: [scope.row.balance !== '']
//             }"
//       or
//        v-permission="resetPaymentPassword"
//             `
//   )
// }
//
// export default {
//   inserted(el, binding, vnode) {
//     check(el, binding, vnode)
//   },
//   update(el, binding, vnode) {
//     check(el, binding, vnode)
//   }
// }
