const config = {
  yxqn: {
    pro: 'https://s.youxiuqn.com/pcweb/',
    dev: '/'
  }
}

const baseUrl = config[process.env.VUE_APP_PROJECT_NAME]
const isPrerender = window.__PRERENDER_INJECTED__ === 'prerender'
if (process.env.VUE_APP_RUNTIME === 'production') {
  /* eslint-disable */
  __webpack_public_path__ = isPrerender ? 'http://localhost:8001/' : baseUrl.pro
}
if (process.env.VUE_APP_RUNTIME === 'test') {
  /* eslint-disable */
  __webpack_public_path__ = isPrerender ? 'http://localhost:8001/' : baseUrl.dev
}
