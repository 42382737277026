import { PROJECT_NAME } from '@/config'
import yxqnRouterConfig from './yxqn'

const config = {
  [PROJECT_NAME.YXQN]: {
    ...yxqnRouterConfig
  }
}

export const routerConfig = {
  ...config[process.env.VUE_APP_PROJECT_NAME]
}
