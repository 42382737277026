<template>
  <img class="GImage" v-bind="$attrs" :src="src | isWebp" alt="">
</template>

<script>
export default {
  name: 'GImage',
  props: {
    // views/index/home/img/icon-section-1_1.png
    gSrc: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      src: ''
    }
  },
  created() {
    this.getImg()
  },
  methods: {
    getImg() {
      if (this.gSrc) {
        import('@/' + this.gSrc).then(res => {
          this.src = res.default
        })
      }
    }
  },
  watch: {
    gSrc() {
      this.getImg()
    }
  }
}

</script>

<style scoped>

</style>
