import Vue from 'vue'
import Router from 'vue-router'
import { routerConfig } from './modules'

Vue.use(Router)

const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace

Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
console.info(routerConfig.base)
export default new Router({
  ...routerConfig.base,
  scrollBehavior: () => ({ y: 0 }),
  routes: routerConfig.constantRouterMap
})
