<template>
  <div class="sidebar" ref="sidebar" :class="{isPositionFixed:isPositionFixed}">

    <template v-if="navType === 'recruit'">
      <div class="release-content" v-permission="PERMISSIONS.releaseJob" key="recruit">
        <div class="release-btn">
          发布岗位
          <div class="release-content_pop-up">
            <div class="release-select">
              <div @mouseover="releaseItemHover = 20"
                   :class="{hover:releaseItemHover === 20, on:recruitType === 20}"
                   @click="checkAuth(20)">全职招聘
              </div>
              <div @mouseover="releaseItemHover = 21"
                   :class="{hover:releaseItemHover === 21, on:recruitType === 21}"
                   @click="checkAuth(21)">兼职招聘
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        :visible.sync="centerDialogVisible"
        width="510px"
        center>
        <div style="text-align: center">
          <img src="../../../../assets/global/sb.png" height="83" width="80"/>
          <p style="font-size: 18px; color:#333;margin:20px 0;font-weight: bold">认证提示</p>
          <p style="font-size: 16px;">{{authTipText}}</p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button class="toB-btn" @click="goToAuth()">好 的</el-button>
            <el-button @click="centerDialogVisible = false">取 消</el-button>
          </span>
      </el-dialog>
    </template>
    <template v-else-if="navType === 'hro'">
      <div class="release-content"
           key="hroSign"
           v-permission="PERMISSIONS.hroSign"
      >
        <router-link :to="{path:'/hroSign/hroSign'}" tag="div" class="release-btn">
          发起签约
        </router-link>
      </div>
      <div
        key="hroPayoff"
        v-permission="PERMISSIONS.hroPayoff"
        class="release-content">
        <div
          @click="hroPayoff" class="release-btn">
          薪酬发放
        </div>
      </div>
    </template>
    <template v-if="navType === 'crowdSourcingServices'">
      <div class="release-content" key="crowdSourcingServices">
        <div
        @click="toReleaseTask"
          data-test-name="toReleaseTask"
          class="release-btn"
          key="releaseTask"
          v-permission="PERMISSIONS.releaseTask"
        >
          发布任务
        </div>
        <div
          v-permission="{
            permission: PERMISSIONS.subpackage,
            condition: [userData.fastPackage === fastPackage]
          }"
          data-test-name="toSubpackageTask"
          class="release-btn"
          key="subpackage"
          @click="goSubpackage"
        >
          快速分包
        </div>
      </div>
    </template>
    <sidebar
      :type="{
        key: 'type',
        value: navType
      }"
      :name="nameData[navType].name"
    ></sidebar>
    <sidebar
      style="margin-top: 40px"
      v-if="nameData[navType].otherMenu && nameData[navType].otherMenu.complianceManagement"
      :type="{
        key: 'otherType',
        value: nameData[navType].otherMenu.complianceManagement.key
      }"
      :name="nameData[navType].otherMenu.complianceManagement.name"
    ></sidebar>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import sidebar from './sidebar'
import storage from '@/api/storage'
import { menuData, hroTelephone, COMPANY_STOP_STATUS } from '@/api/config'
const fastPackage = 1 // 快速分包
export default {
  inject: ['reload'],
  props: {
    checkFlag: {
      default: true,
      type: Boolean
    },
    completeAuthInfoTipFlag: {
      default: false,
      type: Boolean
    }
  },
  components: { sidebar },
  data () {
    return {
      fastPackage,
      nameData: menuData,
      centerDialogVisible: false,
      authTipText: '',
      recruitType: null,
      releaseItemHover: null,
      navType: '',
      isPositionFixed: false,
      goSubpackageLoading: false // 跳快速分包防重复提交
    }
  },
  computed: {
    ...mapGetters([
      'permission_routers',
      'sidebar',
      'userData',
      'companyAuthInfo',
      'salaryEpibolicComInfo'
    ])
  },
  created () {
    this.navType = this.$route.meta.type
  },
  mounted () {
    if (this.$router.currentRoute.path === '/recruit/release-job') {
      this.recruitType = this.$router.currentRoute.query.recruitType
    }
    this.offsetTop = this.$refs.sidebar.offsetTop
    document.addEventListener('scroll', this.sidebarPosition)
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.sidebarPosition)
  },
  methods: {
    sidebarPosition () {
      if (this.scrollTimer) {
        return
      }
      this.scrollTimer = true
      const offsetTop = this.offsetTop
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      const bodyClientHeight = document.body.clientHeight || document.documentElement.clientHeight
      const sidebarHeight = this.$refs.sidebar.clientHeight
      this.isPositionFixed = scrollTop > offsetTop - 20 && sidebarHeight < bodyClientHeight
      setTimeout(() => {
        this.scrollTimer = false
      }, 30)
    },
    checkAuth (recruitType) {
      // this.$router.push({path: '/recruit/release-job'})
      if (this.userData.authStatus !== 2) {
        this.authTipText = '您当前没有进行企业认证哦！是否前去认证？'
        this.centerDialogVisible = true
      } else {
        // 检查角色和企业资料
        if (!this.checkFlag && this.userData.hrStatus !== 1) {
          this.authTipText = '您还没有完善您的企业资料哦！是否前去完善？'
          this.centerDialogVisible = true
        } else {
          this.setLocalStorage()
          if (this.$router.currentRoute.path === '/recruit/release-job') {
            this.$router.push({
              query: {
                recruitType: recruitType
              }
            })
            this.reload()
          } else {
            this.$router.push({
              path: '/recruit/release-job',
              query: { recruitType: recruitType }
            })
          }
          this.recruitType = recruitType
        }
      }
    },
    setLocalStorage () {
      // 岗位管理的筛选条件  先存储本地 返回岗位管理时回显
      const jobReqData = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        releaseStatus: this.releaseStatus,
        searchWords: this.searchWords,
        comRecruitId: this.comRecruitId
      }
      storage.setStorage('jobReqData', jobReqData)
    },
    goToAuth () {
      if (this.userData.authStatus !== 2) {
        this.$router.push({ path: '/company-cert/company-cert' })
      } else {
        this.$router.push({ path: '/set/company-info' })
      }
      this.centerDialogVisible = false
    },
    hroPayoff() {
      this.reload()
      this.$router.push({
        path: '/hroPayoff/hroPayoff'
      })
    },
    toReleaseTask() {
      if (this.$store.getters.companyAuthInfo.stopStatus === COMPANY_STOP_STATUS.map.stop.value) {
        this.$message.error('账户已停用，请联系平台客服人员！')
        return
      }
      this.$router.push({ path: '/releaseTask/releaseTask' })
    },
    /**
     * 快速分包
     */
    goSubpackage() {
      if (this.$store.getters.companyAuthInfo.stopStatus === COMPANY_STOP_STATUS.map.stop.value) {
        this.$message.error('账户已停用，请联系平台客服人员！')
        return
      }
      if (this.goSubpackageLoading) {
        return
      }
      this.goSubpackageLoading = true
      this.$store.dispatch('GetInfo').then(res => {
        if (!this.userData.existActiveSupply) {
          this.$alert(`<div style="text-align: left;color: #303133">服务商配置错误，请联系签订合同的平台商务或平台众包服务客服！平台众包客服电话：<span class="color-F56C6C">${hroTelephone}</span></div>`, '提示', {
            confirmButtonText: '知道了',
            customClass: 'customClassMsgBox',
            dangerouslyUseHTMLString: true
          })
          return
        }
        if (this.$route.path === '/subpackage/subpackage') {
          this.reload()
        } else {
          this.$router.push({
            path: '/subpackage/subpackage'
          })
        }
      }).catch(err => {
        console.info(err)
      }).finally(() => {
        this.goSubpackageLoading = false
      })
    }
  },
  watch: {
    $route () {
      this.navType = this.$route.meta.type
      this.recruitType = null
    }
  }
}
</script>

<style lang="less">
  @import "index";
</style>
<style scoped lang="less">
  .new {
    display: inline-block;
    margin-right: -16px;
    width: 17px;
    height: 9px;
    background: url('../../../../assets/global/new1.png') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
