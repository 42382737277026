import promise from 'es6-promise'
import axios from 'axios'
import { Notification, MessageBox } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'
import { statusCode } from 'api/config'
import md5 from 'md5'
import { setBaseKey } from 'api/heatJob'
import { mockUrl, baseMockUrl } from './mockUrl'
import sentryTool from '@/sentry'
import router from '@/router'
import cryptojs from './cryptojs'

promise.polyfill()

function IEVersion() {
  const userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
  const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 // 判断是否IE<11浏览器
  const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
  if (isIE || isIE11) {
    return 1
  } else {
    return -1// 不是ie浏览器
  }
}

let modelFlag = true // 避免登录过期 多次弹框

const ieVersion = IEVersion()
const ERROR_CODE = [statusCode.FAIL]

// 创建axios实例
const service = axios.create({
  // baseURL: BASEURL, // api的base_url/
  timeout: 50000 // 请求超时时间/s
})
axios.defaults.withCredentials = false
// request拦截器
service.interceptors.request.use(config => {
  if (ieVersion === 1) {
    if (config.method === 'get' || config.method === 'GET') {
      config.params = Object.assign({}, { randomTimeYX: +new Date() }, config.params)
    }
  }
  if (mockUrl.includes(config.url)) {
    config.url = baseMockUrl + config.url
  }
  // 防爬
  if (config.url === '/api/internet/web/job/list') {
    const nowTime = new Date().getTime()
    config.params = Object.assign({}, config.params, { flash: nowTime })
    const mdKey = config.params.pageNum.toString() + config.params.pageSize.toString() + config.params.flash.toString()
    config.headers = Object.assign({}, config.headers, { ChilliPepper: setBaseKey(md5(mdKey), 24) })
  }
  if (store.getters.token && store.getters.token !== 'undefined') {
    config.headers['token'] = getToken() || '' // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    // 与后端约定，部分接口会加密，前端需解密
    if (typeof response.data.data === 'string' && /^encrypt=/.test(response.data.data)) {
      // 解密
      response.data.data = response.data.data.replace(/^encrypt=/, '')
      response.data.data = cryptojs.decrypt(response.data.data)
      response.data.data = JSON.parse(response.data.data)
    }
    /**
     * code为非000000是抛错 可结合自己业务进行修改
     */
    const ignoreCode = response.config.headers.ignoreCode || []
    const requestCode = response.data.code
    const res = response.data || {}
    if (response.config.responseType === 'blob') {
      return response.data
    }
    if (requestCode !== statusCode.OK) {
      // 000001:未登录;
      if (requestCode === statusCode.NOT_AUTHENTICATED || requestCode === statusCode.LOGIN_EXPIRED) {
        if (modelFlag) {
          modelFlag = false
          store.dispatch('FedLogOut').then(() => {
            router.push({ path: '/login' })
            location.reload()// 为了重新实例化vue-router对象 避免bug
          }).catch((err) => {
            console.log(err)
          })
          MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
            confirmButtonText: '重新登录',
            showCancelButton: false,
            showClose: false,
            closeOnClickModal: false,
            type: 'warning'
          }).then(() => {
            modelFlag = true
          }).catch((err) => {
            console.log(err)
          })
        }
      } else if (!ignoreCode.includes(requestCode)) {
        Notification({
          message: res.msg + '，请刷新页面重试！' || '网络异常，请刷新页面重试！',
          type: ERROR_CODE.includes(requestCode) ? 'error' : 'warning',
          duration: 5 * 1000
        })
      }
      // 上报接口调用错误事件
      sentryTool.captureEvent({
        message: '接口调用返回错误',
        level: 'warning',
        extra: {
          result: response.data,
          url: response.config.url,
          data: response.config.data,
          params: response.config.params
        }
      })
      return Promise.reject(response.data)
    } else {
      return response.data
    }
  },
  error => {
    if (error.response.status === 401) {
      if (modelFlag) {
        modelFlag = false
        store.dispatch('FedLogOut').then(() => {
          modelFlag = true
          router.push({ path: '/login' })
          // location.reload()// 为了重新实例化vue-router对象 避免bug
        }).catch((err) => {
          console.log(err)
        })
      }
    } else {
      Notification({
        message: error.message || '服务器出小差了',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
