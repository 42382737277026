<template>
  <section class="app-main">
    <div class="auth-tip"
         :class="[directTipType.type?authTipArr[directTipType.type-1]:'',tipPage==='/recruit/release-job'?'auth-tip-direct-no':'']"
         v-if="TipFlag">
      <template v-if="tipPage === '/recruit/job'">
        选择直面的求职者，会直接进入待面试状态，如要查看，请在待面试状态查看
      </template>
      <template v-if="tipPage === '/recruit/release-job'">
        <img src="../../../assets/newGlobal/directTip0.png" class="directIcon">
        发布岗位功能需付费使用
      </template>
      <template v-if="tipPage === '/recruit/directAudition'&&directTipType.type===1">
        <img src="../../../assets/newGlobal/directTip0.png" class="directIcon">
        预约直面功能需续费使用
      </template>
      <template v-if="tipPage === '/recruit/directAudition'&&directTipType.type===2">
        <img src="../../../assets/newGlobal/directTip1.png" class="directIcon">
        直面有效期：{{directTipType.endTime}}
        <span class="renew" @click="renewDirect">续费></span>
      </template>
      <template v-if="tipPage === '/recruit/directAudition'&&directTipType.type===3">
        <img src="../../../assets/newGlobal/directTip2.png" class="directIcon">
        直面已到期
        <span class="renew" @click="renewDirect">续费></span>
      </template>
      <i @click="closeAuthTip" class="el-icon-close auth-tip-close"
         v-if="tipPage === '/recruit/job'|| directTipType.type===1 ||tipPage==='/recruit/release-job'"></i>
    </div>

    <!--<transition name="fade" mode="out-in">-->
    <!-- <router-view :key="key"></router-view> -->
    <!--面包屑-->
    <div class="auth-tip space-between"
         v-if="userData.authStatus === 3 && $route.path === '/myIndex/my-index'">
      <span>您所提交的企业资质认证审核，未通过审核，驳回理由：{{companyAuthInfo.auditFailReason}}</span>
      <span class="auth-tip_btn" @click="goCompleteAuth(false)">重新认证</span>
    </div>
    <bread-crumbs></bread-crumbs>
    <keep-alive :include="keepAliveIncludes">
      <router-view @directType="directJobId" :refresh="refresh"></router-view>
    </keep-alive>
    <el-dialog width="500px" :visible.sync="payDialog" class="payDialog">
      <consume-component :pay-type="2" @closePayDialog="closeDialog" @butTimeId="buyTime" :isRenew="true"
                         v-if="payDialog"></consume-component>
    </el-dialog>

    <el-dialog width="500px" :visible.sync="rechargeDialog" class="rechargeDialog">
      <recharge-componet @closeRechargeDialog="closeRechargeDialog" :rechargePay="rechargePay"
                         v-if="rechargeDialog"></recharge-componet>
    </el-dialog>
    <!--</transition>-->
  </section>
</template>

<script>
import { renewDir } from '@/api/job'
import ConsumeComponent from '../../../components/consumeComponent/index'
import RechargeComponet from '../../../components/rechargeComponet/index'
import breadCrumbs from './breadCrumbs/breadCrumbs'
import { balance } from '@/api/balance'
import { mapGetters } from 'vuex'

export default {
  components: { RechargeComponet, ConsumeComponent, breadCrumbs },
  inject: ['goCompleteAuth'],
  name: 'AppMain',
  data () {
    return {
      TipFlag: 1,
      tipPage: '',
      directTipType: {
        type: '',
        endTime: '',
        jobId: ''
      },
      refresh: false,
      tipPageArr: ['/recruit/job', '/recruit/directAudition', '/recruit/release-job'], // 直面 横条提示页面
      authTipArr: ['auth-tip-direct-no', 'auth-tip-direct-on', 'auth-tip-direct-off'],
      payDialog: false,
      rechargeDialog: false,
      rechargePay: 0,
      directEndTime: '',
      keepAliveIncludes: [],
      subPath: []
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'salaryEpibolicComInfo',
      'companyAuthInfo'
    ])
  },
  async created () {
    this.TipFlag = this.tipPageArr.indexOf(this.$router.currentRoute.path) > -1
    this.tipPage = this.$router.currentRoute.path
  },
  mounted () {
  },
  methods: {
    // 关闭横幅
    closeAuthTip () {
      this.TipFlag = 0
    },
    injob () {
      this.directTipType.type = ''
    },
    directJobId (val) {
      this.directTipType = val
      let dayTime = 0
      dayTime = ((this.directTipType.endTime - new Date().getTime()) / 86400000)
      dayTime = Math.round(dayTime)
      if (dayTime < 1) {
        dayTime = 1
      }
      this.directTipType.endTime = dayTime + '天'
    },
    renewDirect () {
      this.payDialog = true
    },
    closeDialog (val) {
      this.payDialog = val
    },
    closeRechargeDialog (val) {
      this.rechargeDialog = val
    },
    buyTime (val) {
      this.refresh = false
      let myBalance = 0
      const goodsId = val.goodsId
      this.rechargePay = val.discountPrice
      balance(this.$store.getters.userData.comId).then((data) => {
        myBalance = data.data || '0'
        if (val.discountPrice > myBalance) {
          this.rechargeDialog = true
          return
        }
        // if(val)
        this.$confirm('您的当前余额为' + myBalance + '优点，本次续费将使用' + val.discountPrice + '优点，是否确认发布?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'custom-el',
          center: true
        }).then(() => {
          renewDir({ jobId: this.directTipType.jobId, goodsId: goodsId }).then(res => {
            this.$message.success('续费成功！')
            this.refresh = true
            this.payDialog = false
          }).catch(err => {
            if (err.code === '999003') {
              this.rechargeDialog = true
            }
          })
        }).catch(() => {
        })
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (t, f) {
        // 横条提示
        this.TipFlag = this.tipPageArr.indexOf(this.$router.currentRoute.path) > -1
        this.tipPage = this.$router.currentRoute.path
        // 横条提示
        if (t.path === '/recruit/job') {
          this.injob()
        }
        // 缓存页面
        const pathArr = t.path.split('/')
        const pagePath = pathArr[pathArr.length - 1]
        if (!this.subPath.includes(pagePath)) {
          this.keepAliveIncludes = []
          this.subPath = []
        }
        if (!this.keepAliveIncludes.includes(t.name)) {
          this.keepAliveIncludes.push(t.name)
          if (t.meta.subPath) {
            this.subPath = t.meta.subPath
          } else {
            this.subPath = []
          }
        }
      }
    }
  }
}
</script>

<style lang="less">
  .app-main {
  }

</style>

<style lang="less" scoped>
  @import "appMain";
</style>
