/**
 * @desc 用于简单判断两个object是否相等
 * @param a {Object}
 * @param b {Object}
 */
function simpleEquals(a, b) {
  if (a === b) {
    return true
  }
  const isObjectA = isObject(a)
  const isObjectB = isObject(b)
  if (isObjectA && isObjectB) {
    // 两个均是对象
    try {
      const isArrayA = Array.isArray(a)
      const isArrayB = Array.isArray(b)
      if (isArrayA && isArrayB) {
        // 如果都是数组
        if (a.length === b.length) {
          // 如果长度相等
          return a.every((e, i) => {
            // 用every和递归来比对a数组和b数组的每个元素，并返回
            return simpleEquals(e, b[i])
          })
        }
        // 长度都不等直接返回false
        return false
      } else if (a instanceof Date && b instanceof Date) {
        // 如果是Date 则直接getTime 比较
        return a.getTime() === b.getTime()
      } else if (!isArrayA && !isArrayB) {
        // 对象的比较
        // 拿到两个对象的key
        const keysA = Object.keys(a)
        const keysB = Object.keys(b)
        if (keysA.length === keysB.length) {
          // 如果keys相等
          return keysA.every(key => {
            // 用every和递归来比对a对象和b对象的每个元素值，并返回
            return simpleEquals(a[key], b[key])
          })
        }
        // 长度都不等直接返回false
        return false
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  } else if (!isObjectA && !isObjectB) {
    // 如果都不是对象则按String来处理
    return String(a) === String(b)
  } else {
    return false
  }
}

function isObject(obj) {
  return (
    obj !== null && Object.prototype.toString.call(obj) === '[object Object]'
  )
}

function getTargetByPath(obj, path) {
  const arr = path.split('.')
  const keyPath = arr.slice(0, -1).join('.')
  const key = arr[arr.length - 1]
  const bailRE = /[^\w.$]/

  function parsePath(obj, path) {
    if (bailRE.test(path)) {
      return
    }
    const segments = path.split('.')
    for (let i = 0; i < segments.length; i++) {
      if (!obj) {
        return
      }
      obj = obj[segments[i]]
    }
    return obj
  }
  const target = parsePath(obj, keyPath)
  return { target, key }
}
export default {
  simpleEquals,
  isObject,
  getTargetByPath
}
