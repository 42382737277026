<template>
  <div class="nav-item-container">
    <div class="nav-title-name" v-html="name">
    </div>
    <ul class="yxqn-nav">
      <template v-for="(item,index) in routers">
        <li
          v-if="
              !item.hidden &&
                item[type.key] === type.value &&
                otherType(item) &&
                showingChildrenLen(item.children)
          "
          :key="index"
        >
          <div class="sidebar-one">
            <router-link
              v-if="showingChildrenLen(item.children) === 1"
              :to="{path:item.path + '/' + item.children[0].path}"
              :class="{'on': selected(item.children[0]) }">
              {{item.children[0].meta.title}}
              <svg-icon :icon-class="item.meta.icon" class-name="icon"></svg-icon>
            </router-link>
            <template v-else>
              <span class="menu">
              {{item.meta.title}}
             <svg-icon :icon-class="item.meta.icon" class-name="icon"></svg-icon>
              </span>
            </template>
          </div>
          <div class="sidebar-two"
            v-if="showingChildrenLen(item.children) > 1">
            <template v-for="(val,ind) in item.children">
              <div class="two-name" v-if="!val.hidden" :key="ind">
                <router-link
                  :class="{'on': selected(val)}"
                  :to="{path: item.path + '/' + val.path}">
                  {{val.meta.title}}
                  <span class="new"
                    v-if="val.meta.title==='岗位管理' && $store.getters.unread.jobApplys>0"></span>
                </router-link>
              </div>
            </template>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// 福州仕通的服务商id
const FZ_STONE_SUP_USER_ID =
  process.env.VUE_APP_RUNTIME === 'production' ? 54 : 129

export default {
  name: 'sidebar',
  props: {
    type: {
      required: true,
      type: Object,
      default() {
        return {
          key: '', // 路由配置 type otherType
          value: ''
        }
      }
    },
    name: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapGetters([
      'permission_routers',
      'userData'
    ])
  },
  data() {
    return {
      routers: []
    }
  },
  created() {
    this.setRouters()
  },
  methods: {
    setRouters() {
      this.routers = this.permission_routers
      this.routers.map(item => {
        item.children && item.children.map(i => {
          // 硬编码 福州仕通服务商才展示认证管理
          const employeeCertificationFlag = i.name === 'employeeCertification' &&
            this.userData.supUserId === FZ_STONE_SUP_USER_ID
          // 硬编码，展示门店管理菜单
          const chainModeFlag = i.name === 'doorManage' && !this.userData.chainMode
          if (chainModeFlag) {
            i.hidden = true
          }
          if (employeeCertificationFlag) {
            i.hidden = false
          }
          return i
        })
        return item
      })
    },
    showingChildrenLen (children) {
      if (children) {
        const showingChildren = children.filter(item => {
          return !item.hidden
        })
        return showingChildren.length
      }

      return 0
    },
    selected (children) {
      const subPath = (children && children.meta && children.meta.subPath) || []
      // navKey参数控制菜单高亮的显示值为菜单根路由path，一般为该页面有对应多个菜单高亮展示
      // 如 众包任务管理进入任务详情， 账单管理进入任务详情
      const navKey = this.$router.currentRoute.query.navKey || children.path
      let curPath = this.$route.path
      if (curPath.indexOf('/') !== -1) {
        curPath = curPath.substr(curPath.lastIndexOf('/') + 1)
      }
      return children.path === curPath || (subPath.indexOf(curPath) !== -1 && children.path.indexOf(navKey) !== -1)
    },
    otherType (item) {
      let flag = false
      if (this.type.key === 'type' && !item.otherType) {
        flag = true
      } else if (this.type.key === 'otherType') {
        flag = true
      }
      return flag
    }
  },
  watch: {
    permission_routers() {
      this.setRouters()
    }
  }
}
</script>

<style scoped>

</style>
