const requireAll = requireContext => requireContext.keys().map(requireContext)
const global = require.context('./global', false)
const headerNav = require.context('./headerNav', false)
const newGlobal = require.context('./newGlobal', false)
const rightBottomFloat = require.context('./newGlobal/rightBottomFloat', false)
const newLogin = require.context('./newLogin', false)
const QRCode = require.context('./QRCode', false)
const versionUpdate = require.context('./versionUpdate', false)

requireAll(global)
requireAll(headerNav)
requireAll(newGlobal)
requireAll(rightBottomFloat)
requireAll(newLogin)
requireAll(QRCode)
requireAll(versionUpdate)
