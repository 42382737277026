<template>
  <div class="rightBottomFloat" >
    <div class="list-item">
     <div class="optimumProposal">
       <div
         class="icon-item "
         v-show="showMore"
         @click="optimumProposal.show = !optimumProposal.show"
       >
         <img
           style="width: 20px; height: 18px"
           src="./icon-tiyan.png"
           alt="">
         <span class="item-title">免费体验</span>
       </div>
       <optimumProposal
         v-if="optimumProposal.show"
         :show.sync="optimumProposal.show"
       ></optimumProposal>
     </div>
      <!-- <div
        v-if="ysfIsReady"
        class="icon-item icon-online"
           @click="openYsf">
        <img src="../../assets/global/icon-online.gif" alt="">
        <span class="item-title">在线咨询</span>
      </div> -->
      <!-- <div class="icon-item icon-service"
           v-show="showMore">
        <img src="../../assets/newGlobal/rightBottomFloat/icon-service-un.png" alt="">
        <span class="item-title">客服热线</span>
        <div class="service-phone_content">
          <div class="service-phone_content-hd">
            客服电话：
          </div>
          <div class="service-phone_content-ft">
            <div class="ft-item">
              <div class="key">求职招聘：</div>
              <div class="val" style="color: #3693FF">400-023-1288</div>
            </div>
            <div class="ft-item">
              <div class="key">众包服务：</div>
              <div class="val" style="color: #3693FF">020-89817274</div>
            </div>
            <div class="ft-item">
              <div class="key">工作日：</div>
              <div class="val">9:30-21:30</div>
            </div>
          </div>
        </div>
      </div> -->
      <div
        class="icon-item icon-app"
        v-show="showMore"
      >
        <img src="../../assets/newGlobal/rightBottomFloat/icon-app-un.png" alt="">
        <span class="item-title">APP下载</span>
        <div class="app-download-container">
          <img src="../../assets/QRCode/app-code.png">
          <div>
            优秀青年
            <div style="color: #666;font-size: 14px;margin-top: 10px">安卓版扫码下载</div>
          </div>
        </div>
      </div>
      <div class="icon-item icon-code" v-show="showMore">
        <img src="../../assets/newGlobal/rightBottomFloat/icon-code-un.png" alt="">
        <span class="item-title">二维码</span>
        <div class="qrCode-container">
          <div class="qrCode-hd">
            <div
              class="qrCode-list"
              v-for="(item, index) in list"
              :key="index"
              @mouseover="qrCodeChecked = index"
              :class="{on: qrCodeChecked === index}"
            >
              {{ item.name }}
            </div>
          </div>
          <template v-for="(item, index) in list" >
            <div
              :key="index"
              class="qrCode-ft"
              v-if="qrCodeChecked === index">
              <g-image :g-src="item.img"></g-image>
              <div v-html="item.html"></div>
            </div>
          </template>

        </div>
      </div>
      <div v-if="isShowReturnTop" @click="clickReturnTop"
           class="icon-item icon-return">
          <img src="../../assets/newGlobal/rightBottomFloat/icon-return-un.png" alt="">
        <span class="item-title">返回</span>
      </div>
    </div>
  </div>
</template>

<script>
import optimumProposal from '@/page/homePage/views/index/components/optimumProposal/optimumProposal'

export default {
  name: 'rightBottomFloat',
  components: {
    optimumProposal
  },
  data() {
    return {
      optimumProposal: {
        show: false
      },
      ysfIsReady: false,
      qrCodeChecked: 1, // 右下角悬浮二维码 1公众号 2求职 3招聘
      isShowReturnTop: false, // 是否显示返回顶部按钮
      clickReturnTopFlag: false, // 避免重复点击
      list: [
        {
          name: '公众号',
          html: '优秀青年<br>微信公众号',
          img: 'assets/QRCode/wechatQRcode.png'
        },
        {
          name: '求职',
          html: '优秀青年<br>微信小程序',
          img: 'assets/QRCode/appQRcodeC.jpg'
        },
        {
          name: '招聘',
          html: '优秀青年商家版<br>微信小程序',
          img: 'assets/QRCode/appQRcodeB.jpeg'
        }
      ]
    }
  },
  mounted () {
    document.addEventListener('scroll', this.getIsShowReturnTop)
  },
  created () {
    window.ysf('onready', () => {
      this.ysfIsReady = true
    })
  },
  computed: {
    showMore() {
      return this.$route.meta.type === 'index'
    }
  },
  methods: {
    // 供组件外调用
    showOptimumProposal() {
      this.optimumProposal.show = true
    },
    openYsf() {
      window.ysf('open')
    },
    clickReturnTop () {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      const speed = 5
      const trim = setInterval(() => {
        scrollTop = document.body.scrollTop || document.documentElement.scrollTop
        if (scrollTop <= 1) {
          clearInterval(trim)
          return
        }
        document.body.scrollTop -= document.body.scrollTop / speed
        document.documentElement.scrollTop -= document.documentElement.scrollTop / speed
      }, 10)
    },
    getIsShowReturnTop () {
      const clientHeight = document.body.clientHeight || document.documentElement.clientHeight
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      this.isShowReturnTop = scrollTop > clientHeight
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.getIsShowReturnTop)
  }
}
</script>

<style scoped lang="less">
@import "index";
</style>
