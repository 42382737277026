/* Layout */
/* eslint-disable */
import Layout from 'views/layout/Layout'
import { rolesType } from '@/config'

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * type: ''                       用于菜单渲染 路由类型 （招聘服务（），薪酬外包（salaryEpibolic），众包服务（crowdSourcingServices））
 * meta : {
    title: 'title'               如果有多title情况 如：发布岗位/编辑岗位 页面 默认事发布岗位title, 在如需使用编辑岗位，可以在跳转参数中添加customTitleName字段，如：query:{customTitleName: '编辑岗位'}
    icon: 'svg-name'             the icon show in the sidebar,
    type: ''                     路由类型 （招聘服务（），薪酬外包（salaryEpibolic），众包服务（crowdSourcingServices））
    subPath: ['path']            菜单选中态  在父路由上配置 值为子路由 path
    parentPage: {                parentPage可以有多个面包屑配置
     defaultPage:[                  面包屑配置  数组对应 [1级页面，二级页面，三级页面...]
        {
          path: '/index.html',
          pageName: '热招岗位'
        },
        {
          path: '/heatJobDetails',
          pageName: '岗位详情',
          urlKey: ['jobId'],     当前路由可能会带的参数 值会从当前路由url上取
          query: {}
        }
      ]
      invoiceManagement: [        如需使用invoiceManagement面包屑配置 跳转时query.parentPageKey = invoiceManagement
       {                          例子：<router-link ,query:{navKey: 'invoiceManagement', parentPageKey: 'invoiceManagement'}}"></router-link>
                                                            navKey参数控制菜单高亮的显示值为菜单根路由path，一般为该页面有对应多个菜单高亮展示
                                                            例子 任务管理进入任务详情， 结算单管理进入任务详情
          path: '/invoiceManagement/invoiceManagement',
          pageName: '发票管理'
        },
        {
          path: '/invoiceManagement/invoiceApplicationRecord',
          pageName: '申请记录'
        }
      ]
    }
 }
 **/
const baseRouter = [
  {
    path: '/',
    hidden: true,
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('views/login/index'),
    meta: {
      isUnLogin: true
    },
    hidden: true
  },
  {
    path: '/404',
    component: () => import('views/404'),
    hidden: true
  },
  {
    path: '/notice',
    component: Layout,
    type: 'notice',
    meta: { icon: 'icon-msg' },
    children: [
      {
        path: 'notice',
        name: 'notice',
        component: () => import('views/notice/index'),
        meta: {
          title: '我的消息',
          icon: 'icon-msg',
          type: 'notice'
        }
      }
    ]
  }
  // { path: '*', redirect: '/login', hidden: true }
]

const recruitRouter = [
  { // done
    path: '/myIndex',
    component: Layout,
    meta: { icon: 'icon-home' },
    type: 'recruit',
    children: [
      {
        path: 'my-index',
        name: 'myIndex',
        component: () => import(
          'views/myIndex/index'),
        meta: { title: '招聘首页', icon: 'icon10', type: 'recruit', hiddenTitle: true }
      }
    ]
  },
  { // done
    path: '/recruit',
    component: Layout,
    redirect: '/recruit/job',
    name: 'recruit',
    type: 'recruit',
    meta: { title: '岗位管理', icon: 'icon-recruitment', type: 'recruit' },
    children: [
      {
        type: 'recruit',
        path: 'job',
        name: 'job',
        component: () => import(
          'views/job/index'),
        meta: {
          title: '岗位管理',
          type: 'recruit',
          icon: 'table',
          subPath: ['release-job', 'directAudition']
        }
      },
      {
        path: 'release-job',
        name: 'release-job',
        component: () => import(
          'views/job/releaseJob/index'),
        hidden: true,
        meta: {
          type: 'recruit',
          title: '发布岗位',
          icon: 'table'
        }
      },
      {
        path: 'directAudition',
        name: 'directAudition',
        component: () => import(
          'views/directAudition/index'),
        hidden: true,
        meta: {
          type: 'recruit',
          title: '预约直面',
          parentPage: {
            defaultPage: [{
              path: '/recruit/job',
              pageName: '岗位管理'
            }]
          }
        }
      }
    ]
  },
  { // done
    path: '/apply-record',
    component: Layout,
    meta: { icon: 'icon-home' },
    type: 'recruit',
    children: [
      {
        path: 'apply-record',
        name: 'ApplyRecord',
        component: () => import(
          'views/applyRecord/index'),
        meta: {
          type: 'recruit',
          title: '报名记录',
          icon: 'tree'
        }
      }
    ]
  },
  { // done
    path: '/company-relev-parent',
    meta: { title: '代招企业', icon: 'icon-company-relev' },
    component: Layout,
    redirect: '/company-relev-parent/company-relev',
    type: 'recruit',
    children: [
      {
        path: 'company-relev',
        name: 'CompanyRelev',
        component: () => import(
          'views/companyRelev/index'),
        meta: { type: 'recruit', title: '代招企业', subPath: ['add-company'], roles: [rolesType.AGENT] }
      },
      {
        path: 'add-company',
        name: 'AddCompany',
        component: () => import(
          'views/companyRelev/addCompany/index'),
        hidden: true,
        meta: {
          type: 'recruit',
          title: '添加企业',
          icon: 'tree',
          parentPage: {
            defaultPage: [{
              path: '/company-relev-parent/company-relev',
              pageName: '代招企业'
            }]
          }
        }
      }
    ]
  },
  { // done
    path: '/balance',
    component: Layout,
    meta: { icon: 'icon-balance' },
    type: 'recruit',
    children: [
      {
        path: 'balance',
        name: 'balance',
        component: () => import(
          'views/balance/index'),
        meta: { title: '余额管理', icon: 'tree', subPath: ['about-diamonds'], type: 'recruit' }
      }
    ]
  },
  { // done
    path: '/helpCenter',
    component: Layout,
    meta: { icon: 'icon-help' },
    type: 'recruit',
    children: [
      {
        path: 'help-center',
        name: 'helpCenter',
        component: () => import(
          'views/helpCenter/index'),
        meta: {
          title: '帮助中心',
          icon: 'icon14',
          type: 'recruit'
        }
      }
    ]
  }
]

const accountCenterRouter = [
  { // done
    path: '/set',
    component: Layout,
    redirect: '/set/company-info',
    name: 'Set',
    meta: {
      title: '企业资料',
      icon: 'icon-com-info',
      type: 'accountCenter' // 账号中心
    },
    type: 'accountCenter', // 账号中心
    children: [
      {
        path: 'company-info',
        name: 'CompanyInfo',
        component: () => import(
          'views/companyInfo/index'),
        type: 'accountCenter', // 账号中心
        meta: {
          title: '企业资料',
          icon: 'table',
          type: 'accountCenter' // 账号中心
        }
      }
    ]
  },
  { // done
    path: '/company-cert',
    component: Layout,
    type: 'accountCenter', // 账号中心
    redirect: '/company-cert/company-cert',
    meta: {
      title: '企业认证',
      icon: 'icon-com-auth',
      subPath: ['licenseAuth', 'hrAuth'],
      type: 'accountCenter'// 账号中心
    },
    children: [
      {
        path: 'company-cert',
        name: 'companyCert',
        component: () => import(
          'views/companyCert/index'),
        type: 'accountCenter', // 账号中心
        meta: {
          title: '企业认证',
          icon: 'table',
          subPath: ['licenseAuth', 'hrAuth'],
          type: 'accountCenter'// 账号中心
        }
      },
      {
        path: 'licenseAuth',
        name: 'licenseAuth',
        component: () => import(
          'views/companyCert/companyAuth/index.vue'),
        type: 'accountCenter', // 账号中心
        hidden: true,
        meta: {
          title: '营业执照认证',
          icon: 'tree',
          type: 'accountCenter', // 账号中心
          parentPage: {
            defaultPage: [{
              path: '/company-cert/company-cert',
              pageName: '企业认证'
            }]
          }
        }
      },
      {
        path: 'hrAuth',
        name: 'hrAuth',
        component: () => import(
          'views/companyCert/hrAuth/hrAuth.vue'),
        type: 'accountCenter', // 账号中心
        hidden: true,
        meta: {
          title: '人力资源认证',
          icon: 'tree',
          type: 'accountCenter', // 账号中心
          parentPage: {
            defaultPage: [{
              path: '/company-cert/company-cert',
              pageName: '企业认证'
            }]
          }
        }
      }
    ]
  },
  { // done
    path: '/invoiceSet',
    component: Layout,
    redirect: '/invoiceSet/invoiceSet',
    name: 'InvoiceSet',
    meta: {
      title: '开票设置',
      icon: 'icon-invoice',
      type: 'accountCenter' // 账号中心
    },
    type: 'accountCenter', // 账号中心
    children: [
      {
        path: 'invoiceSet',
        name: 'invoiceSet',
        component: () => import(
          'views/companyCert/invoiceSet/invoiceSet'),
        type: 'accountCenter', // 账号中心
        meta: {
          title: '开票设置',
          icon: 'icon-invoice',
          type: 'accountCenter' // 账号中心
        }
      }
    ]
  },
  { // done
    path: '/security',
    component: Layout,
    type: 'accountCenter', // 账号中心
    redirect: '/security/security',
    meta: {
      title: '账户安全',
      icon: 'icon-secure',
      subPath: ['modify-phone', 'modify-password', 'forget-password'],
      type: 'accountCenter' // 账号中心
    },
    children: [
      {
        path: 'security',
        name: 'Security',
        component: () => import(
          'views/security/index'),
        type: 'accountCenter', // 账号中心
        meta: {
          title: '账户安全',
          icon: 'tree',
          subPath: ['modify-phone', 'modify-password', 'forget-password'],
          type: 'accountCenter' // 账号中心
        }
      },
      {
        path: 'modify-phone',
        name: 'ModifyPhone',
        component: () => import(
          'views/security/modifyPhone/index'),
        hidden: true,
        type: 'accountCenter', // 账号中心
        meta: {
          title: '更换手机',
          type: 'accountCenter', // 账号中心
          icon: 'tree',
          parentPage: {
            defaultPage: [{
              path: '/security/security',
              pageName: '账户安全'
            }]
          }
        }
      },
      {
        path: 'modify-password',
        name: 'ModifyPassword',
        component: () => import(
          'views/security/modifyPassword/index'),
        hidden: true,
        type: 'accountCenter', // 账号中心
        meta: {
          type: 'accountCenter', // 账号中心
          title: '修改密码',
          icon: 'tree',
          parentPage: {
            defaultPage: [{
              path: '/security/security',
              pageName: '账户安全'
            }]
          }
        }
      },
      {
        path: 'forget-password',
        name: 'ForgetPassword',
        component: () => import(
          'views/security/forgetPassword/index'),
        hidden: true,
        type: 'accountCenter', // 账号中心
        meta: {
          type: 'accountCenter', // 账号中心
          title: '忘记密码',
          icon: 'tree',
          parentPage: {
            defaultPage: [
              {
                path: '/security/security',
                pageName: '账户安全'
              },
              {
                path: '/security/modify-password',
                pageName: '修改密码'
              }
            ]
          }
        }
      }
    ]
  },
  { // done
    path: '/notSufficientFunds',
    component: Layout,
    type: 'accountCenter', // 账号中心
    meta: {
      type: 'accountCenter', // 账号中心
      icon: 'icon-balance'
    },
    children: [
      {
        path: 'notSufficientFunds',
        name: 'notSufficientFunds',
        type: 'accountCenter', // 账号中心
        component: () => import('views/crowdSourcingServices/notSufficientFunds/notSufficientFunds'),
        meta: {
          type: 'accountCenter', // 账号中心
          title: '余额提示',
          icon: 'icon-balance'
        }
      }
    ]
  },
  { // done
    path: '/APIManagement',
    component: Layout,
    name: 'APIManagement',
    meta: {
      title: '开发者中心',
      icon: 'icon-com-info',
      type: 'accountCenter' // 账号中心
    },
    type: 'accountCenter', // 账号中心
    children: [
      {
        path: 'APIManagement',
        name: 'APIManagement',
        hidden: true,
        component: () => import(
          'views/accountCenter/APIManagement/APIManagement'),
        type: 'accountCenter', // 账号中心
        meta: {
          title: '开发者中心',
          icon: 'table',
          type: 'accountCenter' // 账号中心
        }
      }
    ]
  }
]

const hroRouter = [
  { // done
    path: '/hroStaffManagement',
    component: Layout,
    name: 'hroStaffManagementPage',
    meta: {
      title: '员工管理',
      icon: 'icon-hro-staff',
      type: 'hro' // 账号中心
    },
    type: 'hro', // 账号中心
    children: [
      {
        path: 'hroStaffManagement',
        name: 'hroStaffManagement',
        component: () => import('views/hro/hroStaffManagement/hroStaffManagement'),
        type: 'hro', // 账号中心
        meta: {
          title: '员工管理',
          icon: 'table',
          type: 'hro' // 账号中心
        }
      }
    ]
  },
  { // done
    path: '/hroPM',
    component: Layout,
    name: 'hroPMPage',
    meta: {
      title: '项目管理',
      icon: 'icon-hro-project',
      type: 'hro' // 账号中心
    },
    type: 'hro', // 账号中心
    children: [
      {
        path: 'hroPM',
        name: 'hroPM',
        component: () => import('views/hro/projectManagement/projectManagement'),
        type: 'hro', // 账号中心
        meta: {
          title: '项目管理',
          icon: 'table',
          type: 'hro'
        }
      }
    ]
  },
  { // done
    path: '/hroPayrollRecords',
    component: Layout,
    name: 'hroPayrollRecordsPage',
    meta: {
      title: '发薪记录',
      icon: 'icon-bill', // 和结算单公用
      type: 'hro'
    },
    type: 'hro',
    children: [
      {
        path: 'hroPayrollRecords',
        name: 'hroPayrollRecords',
        component: () => import('views/hro/hroPayrollRecords/hroPayrollRecords'),
        type: 'hro',
        meta: {
          title: '发薪记录',
          icon: 'table',
          type: 'hro',
          subPath: ['hroPayrollRecordsDetail']
        }
      },
      {
        path: 'hroPayrollRecordsDetail',
        name: 'hroPayrollRecordsDetail',
        component: () => import('views/hro/hroPayrollRecordsDetail/hroPayrollRecordsDetail'),
        type: 'hro',
        hidden: true,
        meta: {
          title: '工单详情',
          icon: 'table',
          type: 'hro',
          parentPage: {
            defaultPage: [{ //  面包屑配置  数组对应 [1级页面，二级页面，三级页面...]
              path: '/hroPayrollRecords/hroPayrollRecords',
              pageName: '发薪记录'
            }]
          }
        }
      }
    ]
  },
  { // done
    path: '/hroPayoff',
    component: Layout,
    name: 'hroPayoffPage',
    meta: {
      title: '薪酬发放',
      icon: 'icon-com-info',
      type: 'hro'
    },
    hidden: true,
    type: 'hro',
    children: [
      {
        path: 'hroPayoff',
        name: 'hroPayoff',
        component: () => import('views/hro/hroPayoff/hroPayoff'),
        type: 'hro',
        meta: {
          title: '薪酬发放',
          icon: 'table',
          type: 'hro'
        }
      }
    ]
  },
  { // done
    path: '/hroSign',
    component: Layout,
    name: 'hroSignPage',
    meta: {
      title: '发起签约',
      icon: 'icon-com-info',
      type: 'hro'
    },
    hidden: true,
    type: 'hro',
    children: [
      {
        path: 'hroSign',
        name: 'hroSign',
        component: () => import('views/hro/hroSign/hroSign'),
        type: 'hro',
        meta: {
          title: '发起签约',
          icon: 'table',
          type: 'hro'
        }
      }
    ]
  },
  { // done
    path: '/hroContractManagementPage',
    component: Layout,
    name: 'hroContractManagementPage',
    meta: {
      title: '签约管理',
      icon: 'icon-contract',
      type: 'hro'
    },
    type: 'hro',
    children: [
      {
        path: 'hroContractManagement',
        name: 'hroContractManagement',
        component: () => import('views/hro/hroContractManagement/hroContractManagement'),
        type: 'hro',
        meta: {
          title: '签约管理',
          icon: 'icon-contract',
          type: 'hro',
          subPath: ['hroSingDetail']
        }
      },
      {
        path: 'hroSingDetail',
        name: 'hroSingDetail',
        component: () => import('views/hro/hroSingDetail/hroSingDetail'),
        type: 'hro',
        hidden: true,
        meta: {
          title: '签约详情',
          icon: 'table',
          type: 'hro',
          parentPage: {
            defaultPage: [
              {
                path: '/hroContractManagementPage/hroContractManagement?navStatus=1',
                pageName: '签约管理'
              }
            ]
          }
        }
      }
    ]
  }

]

const crowdSourcingServicesRouter = [
  { // done
    path: '/contractManagement',
    component: Layout,
    type: 'crowdSourcingServices', // 众包服务
    meta: {
      type: 'crowdSourcingServices', // 众包服务
      icon: 'icon-contract'
    },
    children: [
      {
        path: 'contractManagement',
        name: 'contractManagement',
        type: 'crowdSourcingServices', // 众包服务
        component: () => import('views/crowdSourcingServices/contractManagement/contractManagement'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '签约管理',
          icon: 'icon-contract',
          subPath: ['signDetail']
        }
      },
      {
        path: 'signDetail',
        name: 'signDetail',
        type: 'crowdSourcingServices', // 众包服务
        hidden: true,
        component: () => import('views/crowdSourcingServices/signDetail/signDetail'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '签约详情',
          icon: 'icon-contract',
          parentPage: {
            defaultPage: [{
              path: '/contractManagement/contractManagement',
              pageName: '签约管理'
            }]
          }
        }
      }
    ]
  },
  { // done
    path: '/doorManage',
    component: Layout,
    type: 'crowdSourcingServices', // 众包服务
    meta: {
      type: 'crowdSourcingServices', // 众包服务
      icon: 'icon-door'
    },
    children: [
      {
        path: 'doorManage',
        name: 'doorManage',
        type: 'crowdSourcingServices', // 众包服务
        component: () => import('views/crowdSourcingServices/doorManage/doorManage'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '门店管理',
          icon: 'icon-door',
          subPath: ['signDetail']
        }
      }
    ]
  },
  { // done
    path: '/taskManagement',
    component: Layout,
    type: 'crowdSourcingServices', // 众包服务
    meta: {
      icon: 'icon-task',
      type: 'crowdSourcingServices' // 众包服务
    },
    redirect: '/taskManagement/taskManagement',
    children: [
      {
        path: 'taskManagement',
        name: 'taskManagement',
        type: 'crowdSourcingServices', // 众包服务
        component: () => import(
          'views/crowdSourcingServices/taskManagement/taskManagement'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '任务管理',
          subPath: ['taskDetail', 'taskDetailManagement', 'taskProgress']
        }
      },
      {
        path: 'taskDetail',
        name: 'taskDetail',
        hidden: true,
        type: 'crowdSourcingServices', // 众包服务
        component: () => import(
          'views/crowdSourcingServices/taskDetail/taskDetail'),
        meta: {
          title: '任务详情',
          type: 'crowdSourcingServices', // 众包服务
          parentPage: {
            defaultPage: [{
              path: '/taskManagement/taskManagement',
              pageName: '任务管理'
            }],
            payrollRecord: [{
              path: '/payrollRecord/payrollRecord',
              pageName: '结算单管理'
            }],
            zbDataCentre: [{
              path: '/zbDataCentre/zbDataCentre',
              pageName: '数据中心'
            }]
          }
        }
      },
      {
        path: 'taskDetailManagement',
        name: 'taskDetailManagement',
        hidden: true,
        type: 'crowdSourcingServices', // 众包服务
        component: () => import(
          'views/crowdSourcingServices/taskDetailManagement/taskDetailManagement'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '接单管理',
          parentPage: {
            defaultPage: [{
              path: '/taskManagement/taskManagement',
              pageName: '任务管理'
            }]
          }
        }
      },
      {
        path: 'taskProgress',
        name: 'taskProgress',
        hidden: true,
        type: 'crowdSourcingServices', // 众包服务
        component: () => import(
          'views/crowdSourcingServices/taskDetailManagement/taskProgress/taskProgress'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '接单详情',
          parentPage: {
            defaultPage: [{
              path: '/taskManagement/taskManagement',
              pageName: '任务管理'
            }]
          }
        }
      },
      {
        path: 'taskTemplateManage',
        name: 'taskTemplateManage',
        hidden: true,
        type: 'crowdSourcingServices', // 众包服务
        component: () => import(
          'views/crowdSourcingServices/taskTemplateManage/taskTemplateManage'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '任务模板管理',
          parentPage: {
            defaultPage: [{
              path: '/taskManagement/taskManagement',
              pageName: '任务管理'
            }]
          }
        }
      }
    ]
  },
  { // done
    path: '/payrollRecord',
    component: Layout,
    type: 'crowdSourcingServices', // 众包服务
    meta: {
      type: 'crowdSourcingServices', // 众包服务
      icon: 'icon-bill' // 和发薪记录公用
    },
    redirect: '/payrollRecord/payrollRecord',
    children: [
      {
        path: 'payrollRecord',
        name: 'payrollRecord',
        type: 'crowdSourcingServices', // 众包服务
        component: () => import(
          'views/crowdSourcingServices/payrollRecord/index'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          hiddenTitle: true,
          title: '结算单管理',
          icon: 'icon-bill',
          subPath: ['invoice', 'taskDetail', 'invoiceApplicationRecord', 'billingDetails']
        }
      },
      // {
      //   path: 'invoiceManagement',
      //   name: 'invoiceManagement',
      //   component: () => import(
      //     'views/crowdSourcingServices/invoiceManagement/invoiceManagement'),
      //   hidden: true,
      //   type: 'crowdSourcingServices', // 众包服务
      //   meta: {
      //     title: '发票管理',
      //     type: 'crowdSourcingServices', // 众包服务
      //     parentPage: {
      //       defaultPage: [{ //  面包屑配置  数组对应 [1级页面，二级页面，三级页面...]
      //         path: '/payrollRecord/payrollRecord',
      //         pageName: '结算单管理'
      //       }]
      //     }
      //   }
      // },
      {
        path: 'invoiceApplicationRecord',
        name: 'invoiceApplicationRecord',
        type: 'crowdSourcingServices', // 众包服务
        component: () => import(
          'views/crowdSourcingServices/invoiceApplicationRecord/invoiceApplicationRecord'),
        hidden: true,
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '开票记录',
          parentPage: {
            defaultPage: [
              { //  面包屑配置  数组对应 [1级页面，二级页面，三级页面...]
                path: '/payrollRecord/payrollRecord',
                pageName: '结算单管理'
              }
              // { //  面包屑配置  数组对应 [1级页面，二级页面，三级页面...]
              //   path: '/payrollRecord/invoiceManagement',
              //   pageName: '发票管理'
              // }
            ]
          }
        }
      },
      {
        path: 'invoice',
        name: 'invoice',
        hidden: true,
        type: 'crowdSourcingServices', // 众包服务
        component: () => import(
          'views/crowdSourcingServices/invoice/index'),
        meta: {
          title: '发票信息',
          type: 'crowdSourcingServices', // 众包服务
          parentPage: {
            defaultPage: [
              {
                path: '/payrollRecord/payrollRecord',
                pageName: '结算单管理'
              },
              {
                path: '/payrollRecord/invoiceManagement',
                pageName: '发票管理'
              },
              {
                path: '/payrollRecord/invoiceApplicationRecord',
                pageName: '开票记录'
              }
            ],
            payrollRecord: [
              {
                path: '/payrollRecord/payrollRecord',
                pageName: '结算单管理'
              }
            ],
            taskProcessingDetail: [
              {
                path: '/complianceTask/taskProcessing',
                pageName: '合规任务'
              },
              {
                path: '/complianceTask/taskProcessingDetail',
                urlKey: ['employOrderId', 'taskTypeId', 'serialNo', 'wbOrderId'],
                pageName: '合规详情'
              }
            ]
          }
        }
      },
      {
        path: 'billingDetails',
        name: 'billingDetails',
        hidden: true,
        type: 'crowdSourcingServices', // 众包服务
        component: () => import(
          'views/crowdSourcingServices/billingDetails/billingDetails'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '结算单详情',
          parentPage: {
            defaultPage: [{
              path: '/payrollRecord/payrollRecord',
              pageName: '结算单管理'
            }],
            wallet: [{
              path: '/wallet/wallet',
              pageName: '企业账户'
            }],
            csChannelWallet: [
              {
                path: '/clientManage/clientManage',
                pageName: '客户管理'
              },
              {
                path: '/clientManage/csChannelWallet',
                pageName: '企业账户',
                urlKey: ['comId']
              }
            ]
          }
        }
      }
    ]
  },
  { // done
    path: '/invoiceList',
    component: Layout,
    meta: {
      title: '发票管理',
      icon: 'icon-invoice',
      type: 'crowdSourcingServices'
    },
    type: 'crowdSourcingServices',
    children: [
      {
        path: 'invoiceList',
        name: 'invoiceList',
        component: () =>
          import('views/crowdSourcingServices/invoiceList/invoiceList'),
        type: 'crowdSourcingServices',
        meta: {
          title: '发票管理',
          icon: 'icon-invoice',
          type: 'crowdSourcingServices',
          subPath: ['invoiceDetail', 'invoiceManagement']
        }
      },
      {
        path: 'invoiceDetail',
        name: 'invoiceDetail',
        hidden: true,
        type: 'crowdSourcingServices', // 众包服务
        component: () => import(
          'views/crowdSourcingServices/invoiceDetail/invoiceDetail'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '发票详情',
          parentPage: {
            defaultPage: [{
              path: '/invoiceList/invoiceList',
              pageName: '发票管理'
            }]

          }
        }
      },
      {
        path: 'invoiceManagement',
        name: 'invoiceManagement',
        hidden: true,
        type: 'crowdSourcingServices', // 众包服务
        component: () => import(
          'views/crowdSourcingServices/invoiceManagement/invoiceManagement'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '批量开票',
          parentPage: {
            defaultPage: [{
              path: '/invoiceList/invoiceList',
              pageName: '发票管理'
            }]

          }
        }
      }
    ]
  },
  { // done
    path: '/commissionRecord',
    component: Layout,
    meta: {
      title: '发佣记录',
      icon: 'icon-commission',
      type: 'crowdSourcingServices'
    },
    type: 'crowdSourcingServices',
    children: [
      {
        path: 'commissionRecord',
        name: 'commissionRecord',
        component: () =>
          import('views/crowdSourcingServices/commissionRecord/commissionRecord'),
        type: 'crowdSourcingServices',
        meta: {
          title: '发佣记录',
          icon: 'icon-commission',
          type: 'crowdSourcingServices'
        }
      }
    ]
  },
  { // done
    path: '/employeeCertification',
    component: Layout,
    type: 'crowdSourcingServices', // 众包服务
    meta: {
      type: 'crowdSourcingServices', // 众包服务
      icon: 'icon-employeeCertification'
    },
    children: [
      {
        path: 'employeeCertification',
        name: 'employeeCertification',
        type: 'crowdSourcingServices', // 众包服务
        hidden: true,
        component: () => import('views/crowdSourcingServices/employeeCertification/employeeCertification'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '认证管理',
          icon: 'icon-employeeCertification'
        }
      },
      {
        path: 'stGuide',
        name: 'stGuide',
        type: 'crowdSourcingServices', // 众包服务
        hidden: true,
        component: () => import('views/crowdSourcingServices/stGuide/stGuide'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '福建仕通业务操作指引',
          parentPage: {
            defaultPage: [{
              path: '/employeeCertification/employeeCertification',
              pageName: '认证管理'
            }]
          }
        }
      }
    ]
  },
  { // done
    path: '/wallet',
    component: Layout,
    type: 'crowdSourcingServices', // 众包服务
    meta: {
      type: 'crowdSourcingServices', // 众包服务
      icon: 'icon-wallet'
    },
    children: [
      {
        path: 'wallet',
        name: 'wallet',
        type: 'crowdSourcingServices', // 众包服务
        component: () => import('views/crowdSourcingServices/wallet/wallet'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '企业账户',
          icon: 'icon-wallet'
        }
      }
    ]
  },
  { // done
    path: '/releaseTask',
    component: Layout,
    type: 'crowdSourcingServices', // 众包服务
    meta: {
      type: 'crowdSourcingServices', // 众包服务
      icon: 'icon18'
    },
    redirect: '/releaseTask/releaseTask',
    children: [
      {
        path: 'releaseTask',
        name: 'releaseTask',
        hidden: true,
        type: 'crowdSourcingServices', // 众包服务
        component: () => import('views/crowdSourcingServices/releaseTask/releaseTask'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '发布任务',
          icon: 'icon18'
        }
      }
    ]
  },
  { // done
    path: '/subpackage',
    component: Layout,
    type: 'crowdSourcingServices', // 众包服务
    meta: {
      type: 'crowdSourcingServices', // 众包服务
      icon: 'icon18'
    },
    redirect: '/subpackage/subpackage',
    children: [
      {
        path: 'subpackage',
        name: 'subpackage',
        hidden: true,
        type: 'crowdSourcingServices', // 众包服务
        component: () => import('views/crowdSourcingServices/subpackage/subpackage'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '快速分包',
          icon: 'icon18'
        }
      }
    ]
  },
  { // done
    path: '/zbDataCentre',
    component: Layout,
    type: 'crowdSourcingServices', // 众包服务
    meta: {
      type: 'crowdSourcingServices', // 众包服务
      icon: 'icon-zb-dataCentre'
    },
    children: [
      {
        path: 'zbDataCentre',
        name: 'zbDataCentre',
        type: 'crowdSourcingServices', // 众包服务
        component: () => import('views/crowdSourcingServices/dataCentre/dataCentre'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '数据中心',
          icon: 'icon-zb-dataCentre',
          subPath: ['taskDetail']
        }
      }
    ]
  },
  { // done
    path: '/talentPool',
    component: Layout,
    type: 'crowdSourcingServices', // 众包服务
    meta: {
      type: 'crowdSourcingServices', // 众包服务
      icon: 'icon-hro-staff'
    },
    children: [
      {
        path: 'talentPool',
        name: 'talentPool',
        type: 'crowdSourcingServices', // 众包服务
        component: () => import('views/crowdSourcingServices/talentPool/talentPool'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '人才库',
          icon: 'icon-hro-staff',
          subPath: ['talentDetail']
        }
      },
      {
        path: 'talentDetail',
        name: 'talentDetail',
        type: 'crowdSourcingServices', // 众包服务
        component: () => import('views/crowdSourcingServices/talentPool/talentDetail'),
        hidden: true,
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '服务记录',
          icon: 'icon-zb-dataCentre',
          parentPage: {
            defaultPage: [{
              path: '/talentPool/talentPool',
              pageName: '人才库'
            }]
          }
        }
      }
    ]
  }
]
const crowdSourcingServicesChannelRouter = [
  { // done
    path: '/crowdTaskManage',
    component: Layout,
    name: 'crowdTaskManageLayout',
    meta: {
      title: '任务管理',
      icon: 'icon-task',
      type: 'crowdSourcingServices'
    },
    type: 'crowdSourcingServices',
    otherType: 'crowdSourcingServicesChannel',
    children: [
      {
        path: 'crowdTaskManage',
        name: 'crowdTaskManage',
        component: () =>
          import('views/crowdSourcingServices/crowdSourcingServicesChannel/crowdTaskManage/crowdTaskManage'),
        type: 'crowdSourcingServices',
        otherType: 'crowdSourcingServicesChannel',
        meta: {
          title: '任务管理',
          icon: 'icon18',
          type: 'crowdSourcingServices'
        }
      }
    ]
  },
  { // done
    path: '/cSBill',
    component: Layout,
    name: 'cSBillLayout',
    meta: {
      title: '结算单管理',
      icon: 'icon-bill',
      type: 'crowdSourcingServices'
    },
    type: 'crowdSourcingServices',
    otherType: 'crowdSourcingServicesChannel',
    children: [
      {
        path: 'cSBill',
        name: 'cSBill',
        component: () =>
          import('views/crowdSourcingServices/crowdSourcingServicesChannel/cSBill/cSBill'),
        type: 'crowdSourcingServices',
        otherType: 'crowdSourcingServicesChannel',
        meta: {
          title: '结算单管理',
          icon: 'icon-bill',
          type: 'crowdSourcingServices',
          subPath: ['cSBillDetail']
        }
      },
      {
        path: 'cSBillDetail',
        name: 'cSBillDetail',
        component: () =>
          import('views/crowdSourcingServices/crowdSourcingServicesChannel/cSBillDetail/cSBillDetail'),
        type: 'crowdSourcingServices',
        otherType: 'crowdSourcingServicesChannel',
        hidden: true,
        meta: {
          title: '结算单详情',
          icon: 'table',
          type: 'crowdSourcingServices',
          parentPage: {
            defaultPage: [
              {
                path: '/cSBill/cSBill',
                pageName: '结算单管理'
              }
            ]
          }
        }
      }
    ]
  },
  { // done
    path: '/replaceInvoiceApply',
    component: Layout,
    name: 'replaceInvoiceApplyLayout',
    meta: {
      title: '代申请开票',
      icon: 'icon-invoice',
      type: 'crowdSourcingServices'
    },
    type: 'crowdSourcingServices',
    otherType: 'crowdSourcingServicesChannel',
    children: [
      {
        path: 'replaceInvoiceApply',
        name: 'replaceInvoiceApply',
        component: () =>
          import('views/crowdSourcingServices/crowdSourcingServicesChannel/replaceInvoiceApply/replaceInvoiceApply'),
        type: 'crowdSourcingServices',
        otherType: 'crowdSourcingServicesChannel',
        meta: {
          title: '代申请开票',
          icon: 'icon-invoice',
          type: 'crowdSourcingServices'
        }
      }
    ]
  },
  { // done
    path: '/clientManage',
    component: Layout,
    type: 'crowdSourcingServices', // 众包服务
    otherType: 'crowdSourcingServicesChannel',
    meta: {
      type: 'crowdSourcingServices', // 众包服务
      icon: 'icon-hro-staff'
    },
    redirect: '/clientManage/clientManage',
    children: [
      {
        path: 'clientManage',
        name: 'clientManage',
        type: 'crowdSourcingServices', // 众包服务
        component: () => import('views/crowdSourcingServices/crowdSourcingServicesChannel/clientManage/clientManage'),
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          otherType: 'crowdSourcingServicesChannel',
          title: '客户管理',
          icon: 'icon-com-info',
          subPath: ['clientDetail', 'signContract', 'csChannelWallet']
        }
      },
      {
        path: 'clientDetail',
        name: 'clientDetail',
        component: () => import('views/crowdSourcingServices/crowdSourcingServicesChannel/clientDetail/clientDetail'),
        hidden: true,
        type: 'crowdSourcingServices', // 众包服务
        meta: {
          title: '客户详情',
          type: 'crowdSourcingServices', // 众包服务
          parentPage: {
            defaultPage: [{ //  面包屑配置  数组对应 [1级页面，二级页面，三级页面...]
              path: '/clientManage/clientManage',
              pageName: '客户管理'
            }]
          }
        }
      },
      {
        path: 'signContract',
        name: 'signContract',
        component: () => import('views/crowdSourcingServices/crowdSourcingServicesChannel/signContract/signContract'),
        hidden: true,
        type: 'crowdSourcingServices', // 众包服务
        meta: {
          title: '签约员工',
          type: 'crowdSourcingServices', // 众包服务
          parentPage: {
            defaultPage: [{ //  面包屑配置  数组对应 [1级页面，二级页面，三级页面...]
              path: '/clientManage/clientManage',
              pageName: '客户管理'
            }]
          }
        }
      },
      {
        path: 'csChannelWallet',
        name: 'csChannelWallet',
        type: 'crowdSourcingServices', // 众包服务
        component: () => import('views/crowdSourcingServices/crowdSourcingServicesChannel/channelWallet/channelWallet'),
        hidden: true,
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '企业账户',
          icon: 'icon-wallet',
          parentPage: {
            defaultPage: [{
              path: '/clientManage/clientManage',
              pageName: '客户管理'
            }]
          }
        }
      },
      {
        path: 'queryInsurance',
        name: 'queryInsurance',
        type: 'crowdSourcingServices', // 众包服务
        component: () => import('views/crowdSourcingServices/queryInsurance/queryInsurance'),
        hidden: true,
        meta: {
          type: 'crowdSourcingServices', // 众包服务
          title: '商业保险',
          icon: 'icon-wallet',
          parentPage: {
            defaultPage: [{
              path: '/queryInsurance/queryInsurance',
              pageName: '商业保险'
            }]
          }
        }
      }
    ]
  }
]
const complianceManagement = [
  { // done
    path: '/complianceCondition',
    component: Layout,
    meta: {
      title: '合规管理',
      type: 'crowdSourcingServices',
      icon: 'icon-secure'
    },
    type: 'crowdSourcingServices',
    otherType: 'complianceManagement',
    children: [
      {
        path: 'condition',
        name: 'condition',
        component: () => import('views/complianceManagement/condition/condition'),
        type: 'crowdSourcingServices',
        otherType: 'complianceManagement',
        meta: {
          title: '合规概况',
          icon: 'table',
          type: 'crowdSourcingServices'
        }
      }
    ]
  },
  { // done
    path: '/complianceTask',
    component: Layout,
    meta: {
      title: '合规任务',
      type: 'crowdSourcingServices',
      icon: 'icon-complianceTask'
    },
    type: 'crowdSourcingServices',
    otherType: 'complianceManagement',
    children: [
      {
        path: 'taskProcessing',
        name: 'taskProcessing',
        component: () => import('views/complianceManagement/taskProcessing/taskProcessing'),
        type: 'crowdSourcingServices',
        otherType: 'complianceManagement',
        meta: {
          title: '合规任务',
          icon: 'icon-complianceTask',
          type: 'crowdSourcingServices',
          subPath: ['taskProcessingDetail']
        }
      },
      {
        path: 'taskProcessingDetail',
        name: 'taskProcessingDetail',
        component: () => import('views/complianceManagement/taskProcessingDetail/taskProcessingDetail'),
        type: 'crowdSourcingServices',
        otherType: 'complianceManagement',
        hidden: true,
        meta: {
          title: '合规详情',
          icon: 'table',
          type: 'crowdSourcingServices',
          parentPage: {
            defaultPage: [
              {
                path: '/complianceTask/taskProcessing',
                pageName: '合规任务'
              }
            ]
          }
        }
      }
    ]
  }
]

export const constantRouterMap = [
  ...baseRouter,
  // ...recruitRouter,
  // ...accountCenterRouter,
  // ...crowdSourcingServicesRouter,
  // ...crowdSourcingServicesChannelRouter,
  // ...hroRouter,
  // ...complianceManagement
]

export const asyncRouterMap = []

export const guards = {
  beforeEach(to, from, next) {
    next()
  }
}
