import request from '@/utils/request'
/**
 * 岗位列表
 */
export const webJobList = data => request.get('/api/internet/web/job/list', { params: data })

/**
 * 岗位详情
 */
export const webJobDetail = data => request.get('/api/internet/web/job/detail', { params: data })

export function getAdcode() {
  const dateTime = new Date().toDateString()
  try {
    const cityData = localStorage.getItem('cityData')
    const cityDataTime = localStorage.getItem('cityDataTime')
    if (cityData && cityDataTime === dateTime) {
      return Promise.resolve({
        code: '000000',
        data: JSON.parse(cityData),
        msg: ''
      })
    }
  } catch (e) {
  }
  const url = '/api/internet/web/job/cityWithCounties'
  return request.get(url).then(res => {
    localStorage.setItem('cityData', JSON.stringify(res.data))
    localStorage.setItem('cityDataTime', dateTime)
    return Promise.resolve(res)
  }).catch(err => {
    return Promise.reject(err)
  })
}

/**
 * 企业详情
 */
export const webComDetail = data => request.get('/api/internet/web/company/detail', { params: data })

/**
 * 获取二维码
 * @param headersParams
 * @param params
 */
export function webQrcode(headersParams, params = {}) {
  return request({
    url: '/api/internet/wechat/qrcode',
    method: 'get',
    responseType: 'blob',
    params,
    headers: {
      params: JSON.stringify(headersParams)
    }
  })
}

// md5加密
export function setBaseKey(md, keyNum) {
  const md12 = md.substr(0, keyNum)
  let mdKey = ''
  for (let n = 0; n < md12.length; n++) {
    if (n % 2 === 0) {
      mdKey += md12.charAt(n)
    }
  }
  return mdKey
}
