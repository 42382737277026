import Vue from 'vue'
import BigNumber from 'bignumber.js'
import { checkWebpFeature } from '@/utils'
import CryptoJS from '@/utils/cryptojs'

// import { parseTime } from '../utils'

const digitsRE = /(\d{3})(?=\d)/g
/*
  金额格式化
 */
Vue.filter('currency', function (value, currency, decimals) {
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) return ''
  currency = currency != null ? currency : '$'
  decimals = decimals != null ? decimals : 2
  var stringified = Math.abs(value).toFixed(decimals)
  var _int = decimals
    ? stringified.slice(0, -1 - decimals)
    : stringified
  var i = _int.length % 3
  var head = i > 0
    ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
    : ''
  var _float = decimals
    ? stringified.slice(-1 - decimals)
    : ''
  var sign = value < 0 ? '-' : ''
  return sign + currency + head +
    _int.slice(i).replace(digitsRE, '$1,') +
    _float
})
// eslint-disable-next-line no-unused-vars
const currency = Vue.filter('currency')
/*
  字符串截取
 */
Vue.filter('subStr', function (value = '', subNum) {
  if (value.trim().length <= subNum) {
    return value
  } else {
    return `${value.substr(0, subNum)}...`
  }
})

/*
  字符串截取  加*
 */
Vue.filter('subStrStar', function (value = '', start = 3, end = 7) {
  if (value === '-' || !value) {
    return value || ''
  }
  end = !end || end > value.length ? value.length : end
  const s = new Array(end - start).fill('*').join('')
  return `${value.substr(0, start)}${s}${value.substr(end, value.length)}`
})
/*
  字符串截取  加*
 */
Vue.filter('descAndSubName', function (value = '') {
  if (value === '-' || !value) {
    return value || ''
  }
  const str = CryptoJS.decrypt(value) || value
  const start = 1
  let end = str.length === 2 ? 0 : str.length - 1
  end = !end || end > str.length ? str.length : end
  const s = new Array(end - start).fill('*').join('')
  return `${str.substr(0, start)}${s}${str.substr(end, str.length)}`
})

/*
  年龄计算
   */
Vue.filter('ageCalculation', function (value) {
  if (!value) {
    return
  }
  let returnAge
  const strBirthday = new Date(value)
  const birthYear = strBirthday.getYear()
  const birthMonth = strBirthday.getMonth() + 1
  const birthDay = strBirthday.getDate()

  const d = new Date()
  const nowYear = d.getYear()
  const nowMonth = d.getMonth() + 1
  const nowDay = d.getDate()

  // eslint-disable-next-line eqeqeq
  if (nowYear == birthYear) {
    returnAge = 0// 同年 则为0岁
  } else {
    const ageDiff = nowYear - birthYear // 年之差
    if (ageDiff > 0) {
      // eslint-disable-next-line eqeqeq
      if (nowMonth == birthMonth) {
        const dayDiff = nowDay - birthDay// 日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1
        } else {
          returnAge = ageDiff
        }
      } else {
        const monthDiff = nowMonth - birthMonth// 月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1
        } else {
          returnAge = ageDiff
        }
      }
    } else {
      returnAge = -1
    }
  }

  return returnAge + '岁'
})

// eslint-disable-next-line no-unused-vars
const subStr = Vue.filter('subStr')

// 手机格式 123-4567-8910
Vue.filter('phoneFmt', function (val, type = '-') {
  return val.slice(0, 3) + type + val.slice(3, 7) + type + val.slice(7, 11)
})

//
Vue.filter('idCardFmt', function (val, type = ' ') {
  return val.slice(0, 6) + type + val.slice(6, -4) + type + val.slice(-4, val.length)
})

Vue.filter('decrypt', function (val, def = '') {
  if (val == null) {
    return def
  }
  return CryptoJS.decrypt(val) || val
})
Vue.filter('encrypt', function (val) {
  return CryptoJS.encrypt(val)
})

/*
  时间格式化
 */
export const dateFmt = function (time, fmt = 'yyyy-MM-dd hh:mm:ss') {
  if (!time) {
    return '-'
  }
  const value = new Date(time)
  var o = {
    'M+': value.getMonth() + 1, //  月份
    'd+': value.getDate(), //  日
    'h+': value.getHours(), //  小时
    'm+': value.getMinutes(), //  分
    's+': value.getSeconds(), //  秒
    'q+': Math.floor((value.getMonth() + 3) / 3), //  季度
    S: value.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (value.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}
Vue.filter('dateFmt', dateFmt)

// 格式化时间段 2021-1-1 20:00~明日 20:00
export function formatTimeQuantum(beginTime, endTime, separator = '~') {
  if (!beginTime || !endTime) {
    return '-'
  }
  const newBeginTime = new Date(beginTime)
  const newEndTime = new Date(endTime)
  let endTimeFmt = 'yyyy-MM-dd hh:mm'
  if (
    newBeginTime.getFullYear() === newEndTime.getFullYear()
  ) {
    if (
      newBeginTime.getMonth() === newEndTime.getMonth() &&
        newEndTime.getDate() - newBeginTime.getDate() === 1
    ) {
      endTimeFmt = '次日 hh:mm'
    } else {
      endTimeFmt = 'MM-dd hh:mm'
    }
  }
  return `${dateFmt(beginTime, 'yyyy-MM-dd hh:mm')} ${separator} ${dateFmt(endTime, endTimeFmt)}`
}

// eslint-disable-next-line no-unused-vars
// const dateFmt = Vue.filter('dateFmt')

Vue.filter('remainingExpireTime', function (value) {
  if (value > 1000 * 60 * 60 * 24) {
    return parseInt(value / 1000 / 60 / 60 / 24) + '天'
  }
  if (value > 1000 * 60 * 60) {
    return parseInt(value / 1000 / 60 / 60) + '小时'
  }
  if (value > 1000 * 60) {
    return parseInt(value / 1000 / 60) + '分钟'
  } else {
    return '1分钟'
  }
})

/*
  时间对比
 */
Vue.filter('formatTime', function (time, format) {
  if (!time) {
    return '-'
  }
  const DAY_MILLISECOND = 1000 * 60 * 60 * 24
  const HOUR_MILLISECOND = 1000 * 60 * 60
  const MINUTE_MILLISECOND = 1000 * 60
  let date = null
  if (typeof time === 'number') {
    date = new Date(time)
  } else if (typeof time === 'string') {
    // yyyy-MM-dd HH:mm:ss
    date = new Date(
      parseInt(time.slice(0, 4)),
      parseInt(time.slice(5, 7)) - 1,
      parseInt(time.slice(8, 10)),
      parseInt(time.slice(11, 13)),
      parseInt(time.slice(14, 16)),
      parseInt(time.slice(17, 19))
    )
  } else if (time instanceof Date) {
    date = time
  } else {
    return time
    // throw 'bad param'
  }
  const between = new Date().getTime() - date.getTime()
  // if (between > YEAR_MILLISECOND) {
  //   return parseInt((between - YEAR_MILLISECOND) / YEAR_MILLISECOND + 1) + '年前'
  // }
  // if (between > MONTH_MILLISECOND) {
  //   return parseInt((between - MONTH_MILLISECOND) / MONTH_MILLISECOND + 1) + '月前'
  // }
  // return date.getMonth() + 1 + '月' + date.getDate() + '日' + date.getHours() + '时' + date.getMinutes() + '分'
  if (between > DAY_MILLISECOND) {
    if (format === 'm') {
      const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return date.getMonth() + 1 + '月' + date.getDate() + '日 ' + h + ':' + m
    } else {
      return date.getMonth() + 1 + '月' + date.getDate() + '日'
    }
  }
  if (between > HOUR_MILLISECOND) {
    return parseInt((between - HOUR_MILLISECOND) / HOUR_MILLISECOND + 1) + '小时前'
  }
  if (between > MINUTE_MILLISECOND) {
    return parseInt((between - MINUTE_MILLISECOND) / MINUTE_MILLISECOND + 1) + '分钟前'
  }
  return '刚刚'
})

// eslint-disable-next-line no-unused-vars
const formatTime = Vue.filter('formatTime')

// 转换config的type
Vue.filter('getType', function (typeVal, configType) {
  let nameVal = ''
  configType.map(function (val) {
    // eslint-disable-next-line eqeqeq
    if (val.dictValue == typeVal) {
      nameVal = val.dictName
    }
  })
  return nameVal
})

// eslint-disable-next-line no-unused-vars
const getType = Vue.filter('getType')

/**
 *  数字格式化
 *  @param showDecimals  是否显示小数位   Tips: 本来就有小数的返回值会带小数
 */
Vue.filter('formatNumber', function (value, showDecimals = false) {
  if (!value) {
    return showDecimals ? '0.00' : '0'
  }
  const num = value.toString().split('.')
  // 判断是否需要添加 .00
  if (!num[1] && showDecimals) {
    num[1] = '00'
  }
  if (num[1] && num[1].length <= 1) {
    num[1] = num[1].toString() + '0'
  }
  num[0] = num[0].replace(/(?=(\B)(\d{3})+$)/g, ',')
  return num.join('.')
})


/**
 *  图片是否显示webP
 */
Vue.filter('isWebp', function (value, url = '') {
  let webpUrl = ''
  if (!url) {
    url = value
  }
  if (checkWebpFeature() && !value.includes(';base64,') && !value.includes('Signature')) {
    webpUrl = url + '?x-oss-process=image/format,webp/quality,q_90'
    value = value.replace(url, webpUrl)
  }
  return value
})

/**
 *  用户标签
 */
Vue.filter('userTagColor', function (value) {
  const len = value.length
  if (len <= 3) {
    return 'red'
  } else if (len > 3 && len < 5) {
    return 'orange'
  } else {
    return 'blue'
  }
})


/**
 * 上传的文件页面回显名称处理
 * uploadFileName 生成文件名的方法
 */
Vue.filter('uploadFileOriginalName', function (name) {
  if (!name) {
    return name
  }
  const nameSplit1 = name.split('_-_')
  if (nameSplit1.length === 1) {
    const nameSplit2 = name.split('/')
    return nameSplit2[nameSplit2.length - 1]
  }

  const nameSplitLast = decodeURIComponent(nameSplit1[nameSplit1.length - 1])
  if (nameSplitLast.indexOf('?') === -1) {
    return nameSplitLast
  }
  return nameSplitLast.split('?')[0]
})

// 加法
export function BigNumPlus(...value) {
  // 可累加
  let num = new BigNumber(0)
  value.forEach(item => {
    num = num.plus(item || 0)
  })
  return num.toNumber()
}
// 减法
export function BigNumMinus(...value) {
  // 第一个作为总数，可持续减去一组数字
  let num = new BigNumber(value[0])
  value.forEach((item, i) => {
    if (i > 0) num = num.minus(item || 0)
  })
  return num.toNumber()
}

// 乘法
export function BigNumTimes(...value) {
  // 可累乘
  let num = new BigNumber(1)
  value.forEach(item => {
    if (item === 0) {
      num = num.times(item)
    } else {
      num = num.times(item || 1)
    }
  })
  return num.toNumber().toFixed(2)
}

// 除法
export function BigNumDiv(...value) {
  // 第一个作为被除数，可持续除以一组数字
  let num = new BigNumber(value[0])
  value.forEach((item, i) => {
    if (i > 0) num = num.div(item || 1)
  })
  return num.toNumber()
}
