<template>
  <div data-test-name="jobBuyDialog" v-loading="dialogLoading">
    <div>
      <h3 v-if="payType===1">{{isRenew?'续费':'发布'}}专属岗位</h3>
      <h3 v-if="payType===2">{{isRenew?'续费':'使用'}}直面工具</h3>
      <div class="pay-dialog-content">
        <p class="pay-dialog-info" v-if="payType===1">LBS地图展示、精准曝光、优先认证、增加品牌效益。立即兑换使用</p>
        <p class="pay-dialog-info" v-if="payType===2">独享岗位曝光，免去报名确认，求职者可在预设时间直接上门面试。立即兑换使用</p>
        <div class="pay-items">
          <div class="pay-item"
               :key="item.goodsId"
               v-for="(item) in priceList"
               :class="{'is-check':checkedItem.goodsId===item.goodsId}"
               @click="checkItem(item)">
            <div class="triangle_border" v-if="checkedItem.goodsId===item.goodsId">
              <img src="../../assets/global/checked.png"/>
            </div>
            <h6>
              <span class="pay-month">{{item.remark}}</span>
              <span class="pay-cost">体验价：{{item.discountPrice.toFixed(1)}} 优点/岗位</span>
            </h6>
            <div class="pay-price">
              <span class="pay-price-time">{{item.quantity}}天</span>
              <span class="pay-original-cost">原价：{{item.price.toFixed(1)}} 优点/岗位</span>
            </div>
          </div>
        </div>
        <div class="pay-money">
          <!--<div class="pay-money-all">我的余额：300.00优点</div>-->
          <div class="pay-money-cost">付费：<span>{{checkedItem.goodsId?checkedItem.discountPrice.toFixed(1):0}} 优点</span>
          </div>
        </div>
        <div class="pay-btns">
          <el-button class="pay-keep" @click="closeDialog">取消</el-button>
          <el-button
            class="pay-bug"
            data-test-name="jobBuyBtn"
            @click="buyTime"
          >
            购买
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPrice } from '../../api/common'

export default {
  name: 'consumeComponent',
  props: {
    payType: {
      default: 0, // 1-发布岗位 2-设置直面
      type: Number
    },
    isRenew: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      priceList: [],
      checkedItem: {
        goodsId: ''
      },
      dialogLoading: true
    }
  },
  created () {
    this.getPriceList(this.payType)
  },
  methods: {
    getPriceList (type) {
      getPrice({ goodsType: type }).then(res => {
        this.priceList = res.data
        this.checkedItem = this.priceList[0]
        this.dialogLoading = false
      }).catch((err) => {
        this.dialogLoading = false
        console.log(err)
      })
    },
    checkItem (item) {
      this.checkedItem = item
    },
    closeDialog () {
      this.$emit('closePayDialog', false)
    },
    buyTime () {
      if (this.checkedItem.goodsId) {
        this.$emit('butTimeId', this.checkedItem)
      } else {
        this.$message.error('请选择要订购的套餐！')
      }
    }
  },
  components: {},
  watch: {
    name (val, oldVal) {
      // code
    }
  }
}
</script>

<style scoped>

</style>
