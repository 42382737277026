import request from '@/utils/request'

/**
 *  获取余额
 * @param comId
 */
export const balance = comId => request.get('/api/console/company/balance', { params: { comId } })
/**
 * 获取消费记录
 */
export const costList = data => request.get('/api/console/company/cost_list', { params: data })
