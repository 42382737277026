import * as yxqnConfig from './yxqn'

import { PROJECT_NAME } from '@/config'

const config = {
  [PROJECT_NAME.YXQN]: {
    ...yxqnConfig
  }
}
console.info(process.env.VUE_APP_PROJECT_NAME)

export const setting = {
  ...config[process.env.VUE_APP_PROJECT_NAME]
}
