/**
 * Created by 郭辉 on 2018-06-27
 */
import request from '@/utils/request'
import store from '../store'
import { statusCode } from 'api/config'

/**
 * 获取招聘服务预览岗位
 */
export const getPreviewJobsList = data => request.get('/api/internet/web/job/previewList', { params: data })

/**
 获取岗位列表
 */
export function getJobList (data) {
  let url = '/api/console/job/query'
  const applySmsNewUser = store.getters.userData.applySmsNewUser
  if (applySmsNewUser) {
    url = '/api/console/job/queryByLinkPhone'
  }
  return request.get(url, { params: data })
}
/**
 编辑岗位数据回显
 */
export const getJobDetails = data => request.get('/api/console/job/queryJobMsg', { params: data })

/**
 暂存岗位提交
 */
export const tempSaveJob = data => request.post('/api/console/job/temp/save', data)

/**
  编辑岗位提交
 */
export const modifyJob = data => request.post('/api/console/job/updateJob', data)

/**
  发布岗位
 */
export const releaseJob = data => request.post('/api/console/job/release', data, {
  headers: {
    ignoreCode: [
      statusCode.NOT_FREE_JOB,
      statusCode.INSUFFICIENT_BALANCE
    ]
  }
})

/**
  中介查询关联的用人单位
 */
export const getJobName = data => request.get('/api/console/company/queryAllCompanyStatus', { params: data })

/**
  更改岗位状态
 */
export const changeJobStatus = data => request.post('/api/console/job/close', data)

/**
 * 获取企业资料
 */
export const getCompanyInfo = data => request.get('/api/console/company/get_com_info', { params: data })

/**
 * 获取所有福利标签
 */
export const getTag = data => request.get('/api/console/label/job', { params: data })

/**
 * 获取默认福利标签
 */
export const getDefaultTag = data => request.get('/api/console/label/default/welfare', { params: data })

/**
 * 岗位管理根页面拉取公司列表
 */
export const getComName = data => request.get('/api/console/job/company/type', { params: data })

/**
 * 设置  直面
 */
export const setDirect = data => request.post('/api/console/job/interview/saveDirectInfo', data, {
  headers: {
    ignoreCode: [
      statusCode.BUY_INTERVIEW,
      statusCode.INSUFFICIENT_BALANCE
    ]
  }
})

/**
 * 续费岗位
 */
export const renewJobs = data => request.post('/api/console/job/renew', data)

/**
 * 续费直面
 * @param data
 */
export const renewDir = data => request.post('/api/console/job/interview/renewDir', data)

/**
 * 获取岗位订购关系
 */
export const getJobBuy = data => request.get('/api/console/job/jobSub', { params: data })
