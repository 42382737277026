<template>
<div class="optimum-proposal">
  <div v-if="show" class="optimum-proposal-container">
    <div class="optimum-proposal-container_hd">
      免费获取优化方案
      <div class="close" @click="close">
        <i data-v-bfc45e62="" class="el-icon-arrow-down"></i>
      </div>
    </div>
    <div class="optimum-proposal-container_bd">
      <div class="optimum-proposal-text" style="margin-bottom: 20px;color: rgba(0,41,72,1)">您的信息将完全保密，请放心填写：</div>
      <el-form :model="formData"
               :rules="rules"
               ref="ruleForm"
               :inline="true"
               @validate="validate"
               label-width="0"
               label-position="left">
        <el-form-item label="" prop="name">
          <el-select style v-model="formData.consultType" placeholder="请选择咨询的所需服务">
            <el-option
              v-for="item in consultType"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="companyName">
          <el-input v-model="formData.companyName" maxlength="30" placeholder="请输入企业名称全称（必填）"></el-input>
        </el-form-item>
        <el-form-item label="" prop="contacts">
          <el-input v-model="formData.contacts" maxlength="20" placeholder="请输入真实的联系人（必填）"></el-input>
        </el-form-item>
        <el-form-item label="" prop="contactPhone">
          <el-input v-model="formData.contactPhone" placeholder="请输入联系电话（必填）"></el-input>
        </el-form-item>
        <div :class="{'no-drop': !isCanSubmit}" class="btn" @click="submitForm('ruleForm')">提交</div>
      </el-form>
      <div style="margin-top: 15px;line-height: 17px" class="optimum-proposal-text">为了更详细了解您的需求，请留下您的信息，我们将安排专业顾问与您联系，为您提供专属的优化方案！</div>
    </div>
  </div>
</div>
</template>

<script>
import { saveIndexComInfo } from 'api/common'
import { patternTel } from '@/utils/validate'

const consultType = [
  {
    value: 1,
    name: '灵活用工'
  },
  {
    value: 2,
    name: 'HRO'
  },
  {
    value: 3,
    name: '招聘'
  },
  {
    value: 4,
    name: '结算服务'
  },
  {
    value: 5,
    name: '合伙人咨询'
  }
]

const EVENT = {
  updateShow: 'update:show'
}

export default {
  name: 'optimumProposal',
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      consultType,
      formData: {
        consultType: '',
        companyName: '',
        contacts: '',
        contactPhone: ''
      },
      rules: {
        companyName: [
          { pattern: /^\S+(\s+\S+)*$/, message: '请去除前后空格', trigger: 'blur' },
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        contacts: [
          { pattern: /^\S+(\s+\S+)*$/, message: '请去除前后空格', trigger: 'blur' },
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '请输入联系人电话', trigger: 'blur' },
          { pattern: patternTel, message: '请输入正确的联系人电话', trigger: 'blur' }
        ]
      },
      validateResult: {
        companyName: false,
        contacts: false,
        contactPhone: false
      }
    }
  },
  computed: {
    isCanSubmit() {
      return Object.keys(this.validateResult).every(e => this.validateResult[e])
    }
  },
  methods: {
    close() {
      this.$emit(EVENT.updateShow, false)
    },
    validate(e, f) {
      this.validateResult[e] = f
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return
        }
        saveIndexComInfo(this.formData).then(res => {
          this.$message({
            message: '信息提交成功，专属客服将尽快联系您，请保持手机畅通！',
            type: 'success'
          })
          this.close()
        }).catch(err => {
          console.info(err)
        })
      })
    }
  }
}
</script>

<style lang="less">
  .optimum-proposal {
    .el-form-item__label {
      color: rgba(0, 41, 72, 1);
      font-size: 14px;
    }
    input{
      border-radius: 0 !important;
    }
  }
</style>
<style scoped lang="less">
  @import "~@/styles/mixin";
  .optimum-proposal {
    position: absolute;
    bottom: -224px;
    right: 60px;
    z-index: 600;

    .optimum-proposal-container {
      background-color: #fff;
      z-index: 80;
      width: 340px;
      box-shadow: 0 4px 45px 0 rgba(80, 80, 80, 0.25);

      .optimum-proposal-container_hd {
        background: #199dff;
        height: 44px;
        position: relative;
        text-align: center;
        line-height: 44px;
        color: #fff;
        font-size: 16px;
        .close {
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
          width: 44px;
          height: 44px;
          overflow: hidden;
          text-align: center;
          line-height: 44px;
        }
      }

      .optimum-proposal-container_bd {
        padding: 20px 30px 30px;

        .optimum-proposal-text {
          color: rgba(117, 138, 161, 1);
          font-size: 12px;
        }

        .btn {
          cursor: pointer;
          width: 100%;
          height: 40px;
          background-color: rgba(25, 157, 255, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 1);
          &.no-drop{
            cursor: no-drop;
            opacity: .5;
          }
        }
      }
    }
    .el-input, .el-select {
      width: 280px;
    }
    /deep/ input{
      background-color: rgba(247,250,255,1);
      border-color: transparent;
      &:focus{
        border-color: rgba(25,157,255,1);
      }
    }

    /deep/ .el-input__icon{
      line-height: 40px!important;
    }
  }
</style>
