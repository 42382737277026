import Cookies from 'js-cookie'
import { getJobType, getPageConfig } from 'api/common'
import { setting } from '@/setting'
import { CUSTOM_LOGO_REG, JOB_TYPE_MAP, PART_TIME_MAP } from 'api/config'
const { baseInfo } = setting

const app = {
  state: {
    sidebar: {
      opened: !+Cookies.get('sidebarStatus'),
      withoutAnimation: false
    },
    device: 'desktop',
    positionCityData: {
      adCode: '430100',
      cityIndex: '',
      directFlag: 20,
      fatherAdCode: '',
      fatherName: '长沙市',
      isHot: 20,
      name: '长沙市'
    }, // 定位城市信息
    pageConfig: {
      showRightTool: true,
      logoUrl: '',
      copyright: '',
      favicon: ''
    },
    jobTypeConfig: {
      fullTime: [],
      partTime: [],
      onLinePartTime: [],
      hotJob: [],
      allMap: null
    }
  },
  mutations: {
    SET_JOB_TYPE(state, dataList) {
      const allMap = state.jobTypeConfig.allMap || {}
      function format(dataList) {
        const dataMap = {
          fullTime: {},
          partTime: {},
          onLinePartTime: {},
          hotJob: {}
        }
        const childMap = {}
        for (let i = 0; dataList.length > i; i++) {
          const item = dataList[i]
          allMap[item.code] = item
          if (item.deprecated) continue
          if (!item.fatherId) {
            const data = {
              ...item,
              children: []
            }
            if (item.workType === JOB_TYPE_MAP.map.fullTime.value) {
              if (item.hotFlag) {
                dataMap.hotJob[item.code] = data
              } else {
                dataMap.fullTime[item.code] = data
              }
            } else {
              if (item.ptType === PART_TIME_MAP.map.onLine.value) {
                dataMap.onLinePartTime[item.code] = data
              } else {
                dataMap.partTime[item.code] = data
              }
            }
          } else {
            if (!childMap[item.fatherId]) {
              childMap[item.fatherId] = {
                workType: item.workType,
                ptType: item.ptType,
                hotFlag: item.hotFlag,
                children: []
              }
            }
            childMap[item.fatherId].children.push(item)
          }
        }
        for (const key in childMap) {
          const item = childMap[key]
          if (item.workType === JOB_TYPE_MAP.map.fullTime.value) {
            if (item.hotFlag) {
              dataMap.hotJob[key] && dataMap.hotJob[key].children.push(...item.children)
            } else {
              dataMap.fullTime[key] && dataMap.fullTime[key].children.push(...item.children)
            }
          } else {
            if (item.ptType === PART_TIME_MAP.map.onLine.value) {
              dataMap.onLinePartTime[key] && dataMap.onLinePartTime[key].children.push(...item.children)
            } else {
              dataMap.partTime[key] && dataMap.partTime[key].children.push(...item.children)
            }
          }
        }
        return dataMap
      }
      const dataMap = format(dataList)
      state.jobTypeConfig.fullTime = Object.values(dataMap.fullTime)
      state.jobTypeConfig.partTime = Object.values(dataMap.partTime)
      state.jobTypeConfig.onLinePartTime = Object.values(dataMap.onLinePartTime)
      state.jobTypeConfig.hotJob = Object.values(dataMap.hotJob)
      state.jobTypeConfig.allMap = allMap
    },
    // 设置网站配置信息
    SET_PAGE_CONFIG: (state, pageConfig) => {
      state.pageConfig = pageConfig
    },
    TOGGLE_SIDEBAR: state => {
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1)
      } else {
        Cookies.set('sidebarStatus', 0)
      }
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = false
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set('sidebarStatus', 1)
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    SET_POSITION_CITY_DATA: (state, positionCityData) => {
      state.positionCityData = positionCityData
    }
  },
  actions: {
    getJobType({ commit }, flag) {
      return getJobType(flag).then(res => {
        commit('SET_JOB_TYPE', res.data)
        return Promise.resolve(res)
      })
    },
    getPageConfig: async ({ commit }) => {
      const config = {
        logoUrl: baseInfo.logo,
        copyright: baseInfo.copyright,
        pathname: '/'
      }
      const pathname = window.location.pathname.replace(/#(.*?)$/, '').split('/').filter(item => item)
      const env = process.env.VUE_APP_RUNTIME
      if ((env === 'production' || env === 'test') && CUSTOM_LOGO_REG.test(pathname[0])) {
        getPageConfig(pathname[0]).then(({ data }) => {
          data.pathname = pathname[0]
          commit('SET_PAGE_CONFIG', { ...config, ...data, isCustom: true })
          if (data.favicon) {
            const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link')
            link.type = 'image/x-icon'
            link.rel = 'shortcut icon'
            link.href = data.favicon
            document.getElementsByTagName('head')[0].appendChild(link)
          }
          if (data.title) {
            document.querySelector('head>title').innerText = data.title
          }
        }).catch()
      } else {
        commit('SET_PAGE_CONFIG', {
          showRightTool: true,
          ...config
        })
      }
      // commit('SET_PAGE_CONFIG', {
      //   showRightTool: true,
      //   logoUrl: baseInfo.logo,
      //   copyright: baseInfo.copyright
      // })
    },
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    CloseSideBar ({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    ToggleDevice ({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    }
  }
}

export default app
