import CryptoJS from 'crypto-js'

const key = CryptoJS.enc.Utf8.parse('youxiuqingnianxx') // 16位

export default {
  // aes加密
  encrypt(word) {
    let encrypted = ''
    if (typeof word === 'string' || typeof word === 'number') {
      if (typeof word === 'number') {
        word = word.toString()
      }
      const srcs = CryptoJS.enc.Utf8.parse(word)
      encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })
    } else if (typeof word === 'object') {
      // 对象格式的转成json字符串
      const data = JSON.stringify(word)
      const srcs = CryptoJS.enc.Utf8.parse(data)
      encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })
    }
    return encrypted.toString()
  },
  // aes解密
  decrypt(word) {
    const decrypt = CryptoJS.AES.decrypt(word, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    return decryptedStr.toString()
  }
}
