<template>
  <div>
    <!--Index-->
    <div class="footer-copyright" style="padding-top:20px">
      <div class="marginLeft260" v-if="loginType.type==='recruit'">
        <div style="text-align: center">
          <el-popover
            placement="top"
            width="400"
            trigger="hover">
            <div style="display: flex">
              <div class="popover-item">
                <img src="../../assets/QRCode/wechatQRcode.png">
                <span>"优秀青年"微信公众号</span>
              </div>
              <div class="popover-item">
                <img src="../../assets/QRCode/appQRcodeC.jpg">
                <span>"优秀青年"微信小程序</span>
              </div>
            </div>
            <span slot="reference">
            <img src="../../assets/global/out-wechat.png" width="20"/>
            <span style="display:inline-block;vertical-align: middle">公众号/小程序</span>
          </span>
          </el-popover>
          <span style="display:inline-block;vertical-align: middle;margin-left: 40px">
          <!-- <img src="../../assets/global/out-tel.png"/>
          官方客服：400-023-1288 -->
        </span>
        </div>
      </div>
      <p style="margin-top: 5px" v-if="loginType.type==='crowdSourcingServices'">
        <!-- 官方客服：{{$baseInfo.CSSTelephone}} -->
      </p>
      <p style="margin-top: 5px">
        <a :href="$baseInfo.icpUrl" target="_blank">
          增值电信业务经营许可证：{{$baseInfo.icpNo}}
        </a>
        &nbsp;&nbsp;<a
        href="https://beian.miit.gov.cn" target="_blank">{{$baseInfo.beian}}</a>
      </p>
      <p style="margin-top: 5px">
        {{pageConfig.copyright}}
      </p>
    </div>

    <!--Layout-->

    <!--Login-->
  </div>
</template>

<script>
import { ICPNUM } from 'api/config'
import { mapGetters } from 'vuex'

export default {
  name: 'footerCopyright',
  props: {
    footType: {
      type: Number,
      default: 1
    },
    loginType: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['pageConfig'])
  },
  data () {
    return {
      ipcNum: ICPNUM
    }
  },
  created () {
  }
}
</script>

<style scoped lang="less">
  @import "../../styles/customMixin.less";

  .footer-copyright {
    min-width: 940px;
    width: 100%;
    line-height: 30px;
    font-size: 12px;
    color: @color-gray-s;
    text-align: center;
    padding-bottom: 20px;

    p {
      line-height: 1;
      margin-bottom: 10px;
    }
  }

  .marginLeft260 {
    width: 1200px;
    margin: 0 auto !important;
    line-height: 30px;

    img {
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .footer-copyright a {
    font-size: 12px;
    color: @color-gray-s;
  }

  .popover-item {
    width: 200px;
    display: inline-block;

    img {
      width: 180px;
      display: block;
      margin: 0 auto;
    }

    span {
      display: block;
      margin-top: 5px;
      text-align: center;
    }
  }
</style>
