/**
 * Created by jiachenpan on 16/11/18.
 */

export function isvalidUsername (str) {
  const validMap = ['admin', 'editor']
  return validMap.indexOf(str.trim()) >= 0
}

/* 合法uri */
export function validateURL (textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return urlregex.test(textval)
}

/* 小写字母 */
export function validateLowerCase (str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/* 大写字母 */
export function validateUpperCase (str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/* 大小写字母 */
export function validatAlphabets (str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}


/*
  验证身份证
 */
export function validateIdentity (str) {
  str = str.replace(/[^0-9]/ig, '')
  const reg = /(^\d{15}$)|(^\d{17}(x|X|\d)$)/
  return reg.test(str)
}


export function validatePassword (str) {
  const reg = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$^,\.]).{6,16}$/
  return reg.test(str)
}

/*
  验证邮箱
 */
export const patternEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/

/*
  验证手机号
 */
export const patternTel = /^((1\d{10})|(\d{3,4}-?\d{3,8}-?\d{1,4}))$/


/*
  验证网站
 */
export const patternWebAdd = /^((https|http|ftp|rtsp|mms){0,1}(:\/\/){0,1})[A-Za-z0-9-~]+\.(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~#?=%&_\/])+$/

