import store from '@/store'

export default function checkPermission(value) {
  const role = store.getters.roles
  const permission = store.getters.permission
  const roles = [...role, ...permission]
  const isAdmin = roles.includes('admin')

  if (value && typeof value === 'string') {
    return roles.includes(value) || isAdmin
  }
  if (value && value instanceof Array && value.length > 0) {
    const permissionRoles = value

    return (
      roles.some(r => {
        return permissionRoles.includes(r)
      }) || isAdmin
    )
  } else {
    console.error('need roles! Like v-permission="[\'add\',\'del\']"')
    return false
  }
}
