const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  loginTipForPassword: state => state.user.loginTipForPassword,
  userData: state => state.user.userData,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  roles: state => state.user.roles || [],
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  unread: state => state.user.unread,
  positionCityData: state => state.app.positionCityData,
  salaryEpibolicComInfo: state => state.user.salaryEpibolicComInfo,
  salaryEpibolicOrderInfo: state => state.user.salaryEpibolicOrderInfo,
  companyAuthInfo: state => state.user.companyAuthInfo,
  pageConfig: state => state.app.pageConfig,
  jobTypeConfig: state => state.app.jobTypeConfig,
  roleConfig: state => state.user.roleConfig,
  isChannelUser: state => state.user.isChannelUser,
  permission: state => state.permission.permission.filter(item => item),
  routeTypes: state => state.permission.routeTypes,
  allRoutePath: state => state.permission.allRoutePath
}
export default getters
