import Vue from 'vue'

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

const tracesSampleRate = process.env.VUE_APP_RUNTIME === 'development' ? 0 : 0.1

Sentry.init({
  dsn: 'https://0a5343a835254a588c7965e0abad4b11@sentry.youxiuqn-dev.com/9',
  release: process.env.VUE_APP_SENTRY_RELEASE, // 设置版本，主要用于sourcemap
  environment: process.env.VUE_APP_RUNTIME, // 设置上报环境
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true, // 组件性能跟踪
      attachProps: true, // 组件参数
      logErrors: true, // 开发环境抛出错误
      tracingOptions: {
        trackComponents: true // 开启子组件跟踪 可设置为组件数组name[]
      },
      hooks: [] // 用于跟踪钩子'activate' | 'create' | 'destroy' | 'mount' | 'update'
    }),
    new Integrations.BrowserTracing() // 配合tracing参数,启用跟踪集成
  ],
  enabled: process.env.VUE_APP_RUNTIME === 'production', // 开发环境关闭上传
  tracesSampleRate: tracesSampleRate // 跟踪采样率, 生产环境要降低这个值
})

Vue.prototype.$sentry = Sentry
export default Sentry
