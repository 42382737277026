<template>
  <div v-loading="dialogLoading">
    <h3 v-if="from===1">价格：{{rechargePay}}优点</h3>
    <h3 v-if="from===2">充值提示</h3>
    <div class="pay-dialog-content" style="margin-top: 20px">
      <p class="pay-dialog-mine" v-if="from===1">我的余额：{{balance}}优点</p>
      <p class="pay-dialog-info" v-if="from===1">账户优点余额不足，请用微信扫描下面公众号二维码，前往“优秀青年号”充值。</p>
      <p class="pay-dialog-info" v-if="from===2" style="text-align: center;height: 22px">微信扫码，前往微信公众号“优秀青年号”充值</p>
      <div class="recharge-QRCode">
        <img src="../../assets/QRCode/recharge.png" width="280px"/>
      </div>
      <div class="pay-btns">
        <el-button class="pay-bug" style="width: 380px" @click="closeDialog">扫好了</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { balance } from '../../api/balance'

export default {
  name: 'rechargeComponet',
  props: {
    rechargePay: {
      default: 0,
      type: Number
    },
    from: {
      default: 1, // 1、余额不足 2、balance页
      type: Number
    }
  },
  data () {
    return {
      balance: '',
      dialogLoading: true
    }
  },
  created () {
    balance(this.$store.getters.userData.comId).then(res => {
      this.balance = res.data || '0'
      this.dialogLoading = false
    }).catch(err => {
      this.dialogLoading = false
      console.log(err)
    })
  },
  methods: {
    closeDialog () {
      this.$emit('closeRechargeDialog', false)
    }
  },
  components: {},
  watch: {
    name (val, oldVal) {
      // code
    }
  }
}
</script>

<style scoped>

</style>
