import { PROJECT_NAME } from '@/config'
import { PERMISSIONS } from '@/directives/global/permission/permission.config'
// import { PERMISSIONS } from '@/directives/global/permission/permission.config'

export const baseInfo = {
  name: '优秀青年',
  homePage: '/',
  companyName: '广州南方仕通网络科技有限公司',
  type: PROJECT_NAME.YXQN,
  logo: require('../../assets/pageConfig/logo.png'),
  logoStyle: 'height: 50px;width: 188px',
  copyright: '广州南方仕通网络科技有限公司(仕邦集团旗下公司)版权所有Copyright © 2018-2020 STONE.All Rights Reserved',
  beian: '粤ICP备18078984号',
  isShowICP: true,
  icpNo: '粤B2-20201953',
  icpUrl: 'https://s.youxiuqn.com/common-static/南方仕通ICP.png',
  proUrl: 'https://www.youxiuqingnian.com',
  PRO_H5_SIGN_URL: 'https://dwz.cn/xtsJGNM2',
  ossUrl: 'https://s.youxiuqn.com',
  GDKye: '2339da87c1d05b6c84cc35fe60c0f81b',
  CSSTelephone: '020-28218053',
  isShowFeedbackMenu: true, // 是否显示我要反馈菜单
  layoutNavData: [
    {
      // 渠道模式，特殊处理
      name: '众包服务',
      path: '/crowdTaskManage/crowdTaskManage',
      type: 'crowdSourcingServicesChannel',
      permission: PERMISSIONS.crowdSourcingServicesChannel
    },
    {
      name: '众包服务',
      path: '/payrollRecord/payrollRecord',
      type: 'crowdSourcingServices',
      permission: PERMISSIONS.crowdSourcingServices
    },
    {
      name: 'HRO服务',
      path: '/hroStaffManagement/hroStaffManagement',
      type: 'hro',
      permission: PERMISSIONS.hro
    },
    {
      name: '招聘服务',
      path: '/myIndex/my-index',
      type: 'recruit',
      permission: PERMISSIONS.recruit
    }
  ]
}
