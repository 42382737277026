const baseRouter = [
  {
    path: '/404',
    component: () => import('views/404'),
    hidden: true
  },
  {
    path: '/companyIntroduce',
    name: 'companyIntroduce',
    component: () => import('@/page/homePage/views/index/companyIntroduce/companyIntroduce'),
    hidden: true
  },
  {
    path: '/',
    component: () => import('@/page/homePage/views/index/index'),
    hidden: true,
    redirect: '/index',
    type: 'recruit',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/page/homePage/views/index/home/home'),
        meta: {
          type: 'index',
          isUnLogin: true // 是否不用登录
        },
        hidden: true
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/page/homePage/views/index/aboutUs/aboutUs'),
        meta: {
          type: 'index',
          isUnLogin: true
        },
        hidden: true
      },
      {
        path: '/flexibleEmployment',
        name: 'flexibleEmployment',
        component: () => import('@/page/homePage/views/index/flexibleEmployment/flexibleEmployment'),
        meta: {
          type: 'index',
          isUnLogin: true
        },
        hidden: true
      },
      {
        path: '/openPlatform',
        name: 'openPlatform',
        component: () => import('@/page/homePage/views/index/openPlatform'),
        meta: {
          type: 'index',
          isUnLogin: true
        },
        hidden: true
      },
      {
        path: '/recruitService',
        name: 'recruitService',
        component: () => import('@/page/homePage/views/index/recruitService/recruitService'),
        meta: {
          type: 'index',
          isUnLogin: true
        },
        hidden: true
      },
      {
        path: '/partnerPlan',
        name: 'partnerPlan',
        component: () => import('@/page/homePage/views/index/partnerPlan/partnerPlan'),
        meta: {
          type: 'index',
          isUnLogin: true
        },
        hidden: true
      },
      {
        path: '/crowdSourcingServices',
        name: 'crowdSourcingServices',
        component: () => import('@/page/homePage/views/index/crowdSourcingServices/crowdSourcingServices'),
        meta: {
          type: 'index',
          isUnLogin: true
        },
        hidden: true
      },
      {
        path: '/settlementServices',
        name: 'settlementServices',
        component: () => import('@/page/homePage/views/index/settlementServices/settlementServices'),
        meta: {
          type: 'index',
          isUnLogin: true
        },
        hidden: true
      },
      {
        path: '/hroServices',
        name: 'hroServices',
        component: () => import('@/page/homePage/views/index/hroServices/hroServices'),
        meta: {
          type: 'index',
          isUnLogin: true
        },
        hidden: true
      },
      {
        path: '/insuranceAgency',
        name: 'insuranceAgency',
        component: () => import('@/page/homePage/views/index/insuranceAgency/insuranceAgency'),
        meta: {
          type: 'index',
          isUnLogin: true
        },
        hidden: true
      },
      {
        path: '/index.html',
        name: 'heatJob',
        component: () => import('@/page/homePage/views/heatJob/heatJob'),
        meta: {
          type: 'index',
          title: '热招岗位',
          isUnLogin: true
        }
      },
      {
        path: '/heatJobDetails',
        name: 'heatJobDetails',
        component: () => import('@/page/homePage/views/jobDetails/jobDetails'),
        meta: {
          type: 'index',
          isUnLogin: true,
          title: '岗位详情',
          parentPage: { // parentPage可以有多个面包屑配置 具体看 发票信息（invoice） 路由
            defaultPage: [{ //  面包屑配置  数组对应 [1级页面，二级页面，三级页面...]
              path: '/index.html',
              pageName: '热招岗位'
            }]
          }
        }
      },
      {
        path: '/heatCompanyDetails',
        name: 'heatCompanyDetails',
        component: () => import('@/page/homePage/views/companyDetails/companyDetails'),
        meta: {
          type: 'index',
          isUnLogin: true,
          title: '企业详情',
          parentPage: {
            defaultPage: [
              { //  面包屑配置  数组对应 [1级页面，二级页面，三级页面...]
                path: '/index.html',
                pageName: '热招岗位'
              },
              { //  面包屑配置  数组对应 [1级页面，二级页面，三级页面...]
                path: '/heatJobDetails',
                pageName: '岗位详情',
                urlKey: ['jobId', 'comRecruitId', 'cityName'] // 当前路由可能会带的参数
              }
            ]
          }
        }
      },
      {
        path: '/chooseCity',
        name: 'chooseCity',
        component: () => import('@/page/homePage/views/chooseCity/chooseCity'),
        meta: {
          type: 'index',
          isUnLogin: true,
          title: '城市选择'
        }
      }
    ]
  },
  { path: '*', redirect: '/index', hidden: true }
]

export const constantRouterMap = [
  ...baseRouter
]

export const asyncRouterMap = []

export const guards = {
  beforeEach(to, from, next) {
    next()
  }
}
