import request from '@/utils/request'
import { setting } from '@/setting'
const { baseInfo } = setting
/***
 * 获取短信验证码
 * @param phone
 * @param businessType 业务类型
 */
export const sms = (phone, businessType) => request.post('/api/console/code/sms', { phone, businessType })

/**
 * 同上，获取oss临时token,新的上传逻辑使用
 * @param data ={businessType:'employ'}
 */
export const getOssToken = data => request.get('/api/internet/file/aliyun/ossTempToken', { params: data })

/**
 企业通用数据，获取薪资结构、学历、工作经验等通用配置数据
 */
export function getConfig (data, flag) {
  if (!!window.localStorage[data.binsiness] && flag) {
    return Promise.resolve({
      code: '000000',
      data: JSON.parse(window.localStorage[data.binsiness])
    })
  }
  return request.get('/api/console/data/query', { params: data }).then(res => {
    window.localStorage.setItem(data.binsiness, JSON.stringify(res.data))
    return Promise.resolve(res)
  }).catch(err => {
    return Promise.reject(err)
  })
}


export function getJobType (flag) {
  if (!!window.localStorage['allJobType'] && flag) {
    return Promise.resolve({
      code: '000000',
      data: JSON.parse(window.localStorage['allJobType'])
    })
  }
  const url = '/api/console/job/type/listV2'
  return request.get(url, {}).then(res => {
    window.localStorage.setItem('allJobType', JSON.stringify(res.data))
    return Promise.resolve(res)
  }).catch(err => {
    return Promise.reject(err)
  })
}

/**
 获取认证下拉数据
 */
export const getCompanyAuthType = data => request.get('/api/console/dict/data', { params: data })

/**
 * 城市列表
 */
export const getCityList = data => request.get('/api/console/job/city', { params: data })

/**
 * 查询优点套餐
 */
export const getPrice = data => request.get('/api/console/data/price', { params: data })


/**
 * 首页优化方案 & 开放平台信息提交
 */
export const saveIndexComInfo = data => request.post('/api/console/company/saveIndexComInfo', data)


/**
 * 获取网站配置
 * @param params
 */
export const getPageConfig = params => request.get(`${baseInfo.ossUrl}/file/pageConfig/hroSaas/${params}/config.json`, { withCredentials: false })

/**
 * 签约页面获取合同模板
 */
export const getContractTemplate = data => request.get('/api/console/emp/roster/contract/tmpls', { params: data })

/**
 * 获取异步任务执行结果
 */
export const getAsyncTaskResult = data => request.get('/api/console/asyncTask/taskResult', { params: data })
/**
 * 下载中心列表
 */
export const getDownloadList = params => request.get('/api/console/dlHistory/list', { params })

/**
 * 下载中心文件生成结果轮询
 */
export const getDownloadResult = params => request.get('/api/console/dlHistory/expResult', { params })

/**
 * 增加下载次数
 */
export const addDownloadTimes = params => request.get('/api/console/dlHistory/download', { params })

/**
 * 提交下载任务
 * bizSceneId: 必填，问后端要
 * voStr: {}
 */
export const submitDownLoadTask = params =>
  request.post('/api/console/dlHistory/req2Dl', params)
