import { getInfo, login, logout } from '@/api/login'
import { register } from '@/api/register'
import { getCompanyAuth } from '@/api/companyAuth'
import Vue from 'vue'
import { getToken, removeToken, setToken } from '@/utils/auth'
import { getOrderInfo, getSalaryEpibolicComInfo } from '@/api/salaryEpibolic'
import sentryTool from '@/sentry'
import { getRoles } from 'api/account'
import { toHash } from '@/utils'
import cryptoJS from '@/utils/cryptojs.js'
const user = {
  state: {
    token: getToken() || '',
    userData: {},
    loginTipForPassword: false,
    avatar: '',
    roles: [],
    unread: {
      notices: 0,
      questions: 0,
      jobApplys: 0
    },
    salaryEpibolicComInfo: {
      name: '',
      legalRepName: '',
      orgCode: '',
      legalRepIdNo: '',
      cardNo: '',
      bank: '',
      city: [],
      subbranch: '',
      cFourFactorsStatus: null,
      contractInfo: {}
    },
    salaryEpibolicOrderInfo: {
      employOrder: {},
      payrolls: {},
      certs: []
    },
    companyAuthInfo: {},
    roleConfig: {
      list: [],
      hash: {}
    },
    isChannelUser: false
  },
  mutations: {
    SET_SALARY_EPIBOLIC_ORDER_INFO: (state, salaryEpibolicOrderInfo) => {
      Vue.set(state, 'salaryEpibolicOrderInfo', salaryEpibolicOrderInfo)
    },
    SET_SALARY_EPIBOLIC_COM_INFO: (state, salaryEpibolicComInfo) => {
      Vue.set(state, 'salaryEpibolicComInfo', salaryEpibolicComInfo)
    },
    SET_COMPANY_AUTH_INFO: (state, companyAuthInfo) => {
      const { isDistributor, isConglomerate } = companyAuthInfo
      state.companyAuthInfo = companyAuthInfo
      if (isDistributor || isConglomerate) {
        state.isChannelUser = true
      }
    },
    SET_LOGIN_TIP: (state, loginTipForPassword) => {
      state.loginTipForPassword = loginTipForPassword
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_UNREAD: (state, unread) => {
      state.unread = unread
    },
    CHANGE_UNREAD: (state, type) => {
      if (state.unread[type] > 0 && type === 'notices') {
        state.unread[type] -= 1
      } else {
        state.unread[type] = 0
      }
    },
    SET_USER_DATA: (state, userData) => {
      state.userData = userData
    },
    SET_ROLE_CONFIG: (state, roleConfig) => {
      state.roleConfig.list = roleConfig
      state.roleConfig.hash = toHash(roleConfig, 'roleId')
    },
    SET_ROLES: (state, roles = []) => {
      state.roles = roles
    },
    CLEAN_USER_INFO: (state) => {
      state.isChannelUser = false
      state.companyAuthInfo = {}
    }
  },

  actions: {
    getCompanyAuthInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getCompanyAuth('').then(res => {
          commit('SET_COMPANY_AUTH_INFO', res.data)
          resolve()
        })
      })
    },
    getSalaryEpibolicComInfo({ commit, state }) { // 企业
      return new Promise((resolve, reject) => {
        getSalaryEpibolicComInfo().then(res => {
          commit('SET_SALARY_EPIBOLIC_COM_INFO', res.data)
          resolve()
        })
      })
    },
    getSalaryEpibolicOrderInfo({ commit, state }) { // 工单
      return new Promise((resolve, reject) => {
        getOrderInfo().then(res => {
          commit('SET_SALARY_EPIBOLIC_ORDER_INFO', res.data)
          resolve()
        })
      })
    },
    // 获取未读
    setUnread({ commit, dispatch, state }, unread) {
      return new Promise((resolve, reject) => {
        commit('SET_UNREAD', unread)
        resolve()
      })
    },
    // 读取通知
    readNotice({ commit, dispatch, state }, type) {
      return new Promise((resolve, reject) => {
        commit('CHANGE_UNREAD', type)
        resolve()
      })
    },
    // 登录
    Login({ commit, dispatch, state }, userInfo) {
      var keyword = cryptoJS.encrypt({
        imgCode: userInfo.imgCode,
        loginName: userInfo.loginName,
        smsCode: userInfo.smsCode,
        type: userInfo.type,
        password: userInfo.password
      })
      return new Promise((resolve, reject) => {
        login({ encode: keyword }).then(response => {
          const data = response.data
          commit('SET_TOKEN', data.token)
          commit('SET_LOGIN_TIP', data.loginTipForPassword)
          setToken(data.token)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 注册
    Register({ commit, dispatch, state }, userInfo) {
      return new Promise((resolve, reject) => {
        register(userInfo).then(response => {
          const data = response.data
          commit('SET_TOKEN', data.token)
          setToken(data.token)
          resolve(data)
          setYstUser(data)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        getInfo().then(async response => {
          const data = response.data
          commit('SET_USER_DATA', data)
          commit('SET_ROLES', data.roles || [])

          // 设置前端上报用户信息
          sentryTool.setUser({
            id: data.comId,
            userName: data.companyName || '',
            phone: data.phone,
            token: state.token
          })
          sentryTool.setTag('phone', data.phone)
          getRoles().then(({ data: rolesConfig }) => {
            commit('SET_ROLE_CONFIG', rolesConfig || [])
          })
          await dispatch('getCompanyAuthInfo')
          resolve(data)
          setYstUser(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    cleanUserInfo({ commit, dispatch }) {
      commit('SET_TOKEN', '')
      commit('SET_USER_DATA', {})
      commit('SET_ROLES', [])
      commit('SET_SALARY_EPIBOLIC_COM_INFO', [])
      commit('CLEAN_USER_INFO')
      dispatch('cleanPermission')
      removeToken()
    },
    // 登出
    LogOut({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          dispatch('cleanUserInfo')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit, dispatch }) {
      return new Promise(resolve => {
        dispatch('cleanUserInfo')
        resolve()
      })
    }
  }
}

export default user

function setYstUser(user) {
  window.ysf('config', {
    uid: user.comId,
    name: user.companyName,
    mobile: user.phone,
    level: 1, // vip级别
    data: JSON.stringify([
      // { key: 'real_name', value: '土豪' },
      // { key: 'mobile_phone', hidden: true },
      // { key: 'email', value: '13800000000@163.com' },
      // { index: 0, key: 'account', label: '账号', value: 'zhangsan', href: 'http://example.domain/user/zhangsan' },
      // { index: 1, key: 'sex', label: '性别', value: '先生' },
      // { index: 2, key: 'reg_date', label: '注册日期', value: '2015-11-16' },
      // { index: 3, key: 'last_login', label: '上次登录时间', value: '2015-12-22 15:38:54' },
      // { index: 4, key: 'avatar', label: '头像', value: 'https://xxxxx.jpg' }
    ])
  })
}
